import React, { useEffect, useState } from 'react';
import {
    Box,
    Container,
    Grid,
    makeStyles,
    Typography,
    useMediaQuery,
} from '@material-ui/core';
import ScrollAnimation from 'react-animate-on-scroll';
import { useTranslation } from 'react-i18next';
import i18n from '../../lang';
import { getPatumAny } from '../../database/API';
import moment from 'moment';
import Footer from '../../components/Footer';
import Page from '../../components/Page';
import Header from '../../components/Header';
import { useNavigate } from 'react-router';
import { useSpring } from '@react-spring/core';
import { animated } from '@react-spring/web';
import Loading from '../../components/Loading';

const useStyles = makeStyles((theme) => ({
    root: {
        marginTop: 100,
    },
    main: {
        paddingTop: 100,
        paddingBottom: 200,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        backgroundColor: theme.palette.background.default,
        alignContent: 'center',
    },
    mainM: {
        paddingTop: 50,
        paddingBottom: 200,
        backgroundColor: theme.palette.background.default,
    },
    imatge: {
        width: '100%',
        height: 150,
        backgroundPosition: 'center',
        backgroundSize: 'cover',
        borderRadius: 10,
        marginBottom: 20,
    },
    noticia: {
        flexDirection: 'row',
        justifyContent: 'flex-start',
        alignContent: 'center',
        display: 'flex',
        backgroundColor: 'white',
        cursor: 'pointer',
        padding: 15,
        borderRadius: 20,
        border: '2px solid white',
        transition: '0.3s',
        '&:hover': {
            backgroundColor: 'transparent',
            '& .MuiTypography-body1': {
                color: 'white',
            },
            '& .MuiTypography-h4': {
                color: 'white',
            },
        },
    },
    data: {
        color: theme.palette.text.primary,
        fontSize: 12,
        lineHeight: '0.9',
        marginBottom: 5,
    },
    dataDia: {
        color: theme.palette.text.primary,
        fontSize: 40,
        lineHeight: '0.9',
    },
    titol: {
        marginBottom: 10,
        color: theme.palette.text.secondary,
    },
    dataWrap: {
        textAlign: 'center',
        justifyContent: 'center',
        flexDirection: 'column',
        display: 'flex',
    },
}));

const PatumAny = ({ className, onMobileNavOpen, ...rest }) => {
    const classes = useStyles();
    const { t } = useTranslation();
    const [esdeveniments, setEsdeveniments] = useState();
    const [loading, setLoading] = useState(true);
    const matches = useMediaQuery('(min-width:960px)');
    const navigate = useNavigate();
    const [{ opacity }, set] = useSpring(() => ({
        opacity: 0,
    }));

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    useEffect(() => {
        const get = async () => {
            const { data } = await getPatumAny(i18n.language);
            setEsdeveniments(data);
            setLoading(false);
            set.start({ opacity: loading ? 0 : 1 });
        };
        get();
        i18n.moment.locale(i18n.language);
    }, [loading, set, t]);

    return (
        <Page title={t('patum_any')}>
            <Box className={matches ? classes.main : classes.mainM}>
                <ScrollAnimation
                    animateIn="fadeIn"
                    style={{ transition: '0.5s' }}
                >
                    <Container className={classes.root}>
                        <Header title={t('patum_any')} />
                        <Typography
                            variant="body1"
                            style={{
                                color: '#FFFFFF',
                                whiteSpace: 'pre-line',
                            }}
                        >
                            {t('patum_any_text')}
                        </Typography>
                        {loading && (
                            <Box
                                style={{
                                    width: '100%',
                                    height: 300,
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignContent: 'center',
                                    textAlign: 'center',
                                    alignItems: 'center',
                                }}
                            >
                                <Loading />
                            </Box>
                        )}
                        <animated.div style={{ opacity }}>
                            <Grid container spacing={4}>
                                <Grid item md={12}>
                                    {esdeveniments?.map((item) => {
                                        return (
                                            <Box
                                                my={3}
                                                onClick={() =>
                                                    navigate(
                                                        '/actualitat/agenda/' +
                                                            item.id,
                                                        {
                                                            state: item,
                                                        }
                                                    )
                                                }
                                            >
                                                <Box
                                                    pt={3}
                                                    className={classes.noticia}
                                                >
                                                    <Box
                                                        mx={2}
                                                        className={
                                                            classes.dataWrap
                                                        }
                                                    >
                                                        <Typography
                                                            variant="body1"
                                                            className={
                                                                classes.data
                                                            }
                                                        >
                                                            {i18n
                                                                .moment(
                                                                    item.data
                                                                )
                                                                .format('dddd')}
                                                        </Typography>
                                                        <Typography
                                                            variant="body1"
                                                            className={
                                                                classes.dataDia
                                                            }
                                                        >
                                                            {i18n
                                                                .moment(
                                                                    item.data
                                                                )
                                                                .format('DD')}
                                                        </Typography>
                                                        <Typography
                                                            variant="body1"
                                                            className={
                                                                classes.data
                                                            }
                                                        >
                                                            {i18n
                                                                .moment(
                                                                    item.data
                                                                )
                                                                .format('MMMM')}
                                                        </Typography>
                                                    </Box>
                                                    <Box mx={4}>
                                                        <Typography
                                                            variant="h4"
                                                            className={
                                                                classes.titol
                                                            }
                                                        >
                                                            {item.titol}
                                                        </Typography>

                                                        <Typography
                                                            variant="body1"
                                                            className={
                                                                classes.data
                                                            }
                                                        >
                                                            {t('A les')}{' '}
                                                            {moment(
                                                                item.hora,
                                                                'HH:mm:ss'
                                                            ).format('HH:mm')}
                                                            {' h'}
                                                        </Typography>

                                                        <Typography
                                                            variant="body1"
                                                            className={
                                                                classes.data
                                                            }
                                                        >
                                                            {item.lloc}
                                                        </Typography>
                                                    </Box>
                                                </Box>
                                            </Box>
                                        );
                                    })}
                                </Grid>
                            </Grid>
                        </animated.div>
                    </Container>
                </ScrollAnimation>
            </Box>
            <Footer />
        </Page>
    );
};

export default PatumAny;
