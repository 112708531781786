import React, { useEffect, useState } from "react";
import { getData } from "../database/API.js";
import moment from "moment";
import { Box, Grid, makeStyles, Typography } from "@material-ui/core";
import Loading from "./Loading";

const useStyles = makeStyles((theme) => ({
    container: {
        textAlign: "center",
        justifyContent: "center",
    },
    num: {
        fontSize: 35,
        color: "white",
    },
    text: {
        fontSize: 20,
        color: "white",
    },
}));
const CompteEnrere = () => {
    const classes = useStyles();
    const [data, setData] = useState(0);
    const [loading, setLoading] = useState(true);
    const [days, setDays] = useState(0);
    const [hours, setHours] = useState(0);
    const [min, setMin] = useState(0);
    const [sec, setSec] = useState(0);
    const [fi, setFi] = useState(false);

    useEffect(() => {
        const get = async () => {
            const d = await getData();
            setData(d.data);
            setLoading(false);
        };

        get();
    }, []);

    useEffect(() => {
        const calculateCountdown = () => {
            let diff = moment(data).diff(moment()) / 1000;

            if (diff <= 0) setFi(true);

            const timeLeft = {
                days: 0,
                hours: 0,
                min: 0,
                sec: 0,
            };

            if (diff >= 86400) {
                // 24 * 60 * 60
                timeLeft.days = Math.floor(diff / 86400);
                diff -= timeLeft.days * 86400;
            }
            if (diff >= 3600) {
                // 60 * 60
                timeLeft.hours = Math.floor(diff / 3600);
                diff -= timeLeft.hours * 3600;
            }
            if (diff >= 60) {
                timeLeft.min = Math.floor(diff / 60);
                diff -= timeLeft.min * 60;
            }
            timeLeft.sec = Math.round(diff);

            setDays(timeLeft.days);
            setHours(timeLeft.hours);
            setMin(timeLeft.min);
            setSec(timeLeft.sec);
        };

        const interval = setInterval(() => {
            calculateCountdown();
        }, 1000);

        return () => clearInterval(interval);
    }, [data]);

    function addLeadingZeros(value) {
        value = String(value);
        while (value.length < 2) {
            value = "0" + value;
        }
        return value;
    }
    if (loading || (days === 0 && hours === 0 && min === 0 && sec === 0)) {
        return (
            <Grid
                container
                className={classes.container}
                style={{ height: 82 }}
            >
                <Loading />
            </Grid>
        );
    }

    if (fi) {
        return <Box></Box>;
    } else {
        return (
            <Grid container className={classes.container}>
                <Box mr={2}>
                    <Typography className={classes.num}>
                        {addLeadingZeros(days)}
                    </Typography>
                    <Typography className={classes.text}>d</Typography>
                </Box>
                <Box>
                    <Typography className={classes.num}>
                        {addLeadingZeros(hours)}:
                    </Typography>
                    <Typography className={classes.text}>h</Typography>
                </Box>
                <Box>
                    <Typography className={classes.num}>
                        {addLeadingZeros(min)}:
                    </Typography>
                    <Typography className={classes.text}>min</Typography>
                </Box>
                <Box>
                    <Typography className={classes.num}>
                        {addLeadingZeros(sec)}
                    </Typography>
                    <Typography className={classes.text}>s</Typography>
                </Box>
            </Grid>
        );
    }
};

export default CompteEnrere;
