import { makeStyles } from "@material-ui/core";

const navBarStyle = makeStyles((theme) => ({
    root: {
        display: "flex",
        height: "100%",
        overflow: "hidden",
        width: "100%",
    },
    onTop: {
        paddingTop: 30,
        paddingBottom: 30,
        backgroundColor: theme.palette.background.default,
    },
    onTopHome: {
        paddingTop: 30,
        paddingBottom: 30,
        backgroundColor: "transparent",
    },
    onScroll: {
        paddingTop: 10,
        paddingBottom: 10,
        backgroundColor: theme.palette.background.default,
    },
    wrapper: {
        display: "flex",
        flex: "1",
        overflow: "hidden",
    },
    contentContainer: {
        display: "flex",
        flex: "1 1 auto",
        overflow: "hidden",
    },
    content: {
        flex: "1 1 auto",
        height: "100%",
    },
    navBar: {
        transition: "0.5s",
        justifyContent: "flex-start",
        alignContent: "flex-start",
    },
    toolbar: {
        alignItems: "center",
        justifyContent: "center",
    },
    item: {
        padding: 20,
        "& .active": {
            color: theme.palette.text.secondary + "!important",
            "& .MuiTypography-body1": {
                color: theme.palette.text.secondary + "!important",
            },
            "& $icon": {
                color: theme.palette.text.secondary,
            },
        },
    },
    button: {
        color: theme.palette.text.primary,
        justifyContent: "flex-start",
        letterSpacing: 0,
        textTransform: "none",
        cursor: "pointer",
    },

    buttonMobile: {
        color: theme.palette.text.primary,
        justifyContent: "center",
        letterSpacing: 0,
        textTransform: "none",
        width: "100%",
    },
    subMenuButton: {
        justifyContent: "flex-start",
        marginBottom: 10,
        marginTop: 10,
        textTransform: "none",
        cursor: "pointer",
    },
    icon: {
        marginRight: theme.spacing(1),
    },
    title: {
        marginRight: "auto",
        lineHeight: 1.3,
        transition: "0.3s",
        color: "white",
        "&:hover": {
            color: theme.palette.text.secondary,
        },
    },
    titleMobile: {
        marginRight: "auto",
        lineHeight: 1.3,
        color: theme.palette.primary.main,
    },
    titleSub: {
        marginRight: "auto",
        lineHeight: 1.3,
        transition: "0.3s",
        color: "white",
        paddingTop: 5,
        paddingBottom: 5,
        "&:hover": {
            color: theme.palette.text.secondary,
        },
    },
    active: {
        color: theme.palette.text.secondary + "!important",
        "& .MuiTypography-body1": {
            color: theme.palette.text.secondary + "!important",
        },
        "& $icon": {
            color: theme.palette.text.secondary,
        },
    },
    logo: {
        transition: "0.5s",
        opacity: 1,
    },
    hide: {
        opacity: 0,
        transform: "scale(0.8)",
    },
    itemMobile: {
        display: "flex",
        padding: 15,
    },
    buttonDrawer: {
        backgroundColor: "transparent",
        color: "white",
    },
    margin: {
        margin: theme.spacing(1),
        paddingTop: 4,
    },
    arrow: {
        fill: "white",
    },
    menuItem: {
        fontFamily: "Varela Round",
    },
}));

export default navBarStyle;
