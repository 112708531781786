import React, { useEffect, useState } from "react";
import { withStyles } from "@material-ui/styles";
import {
    Box,
    Fade,
    List,
    ListItem,
    ListItemIcon,
    ListItemText,
} from "@material-ui/core";
import { GetApp } from "@material-ui/icons";
import { getAcords, publicUrl } from "../../database/API";
import Loading from "../../components/Loading";
import moment from "moment/min/moment-with-locales";

const StyledListItem = withStyles((theme) => ({
    root: {
        backgroundColor: "white",
        fontFamily: "Varela Round",
        textTransform: "uppercase",
        color: theme.palette.text.primary,
        borderBottom: "2px solid #d9594e",
        "&:nth-of-type(1)": {
            borderTop: "2px solid #d9594e",
        },
    },

    body: {
        fontFamily: "Varela Round",
        color: theme.palette.text.secondary,
        borderBottom: "1px solid #d9594e",
        fontSize: 14,
    },
}))(ListItem);

export default function Acords() {
    const [acords, setAcords] = useState([]);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const get = async () => {
            const { data } = await getAcords();
            setAcords(data);
            setLoading(false);
        };
        get();
    }, []);

    return (
        <Box>
            <List component="nav" aria-label="main mailbox folders">
                {!loading ? (
                    <Fade in={!loading}>
                        <div>
                            {acords?.map((item) => (
                                <StyledListItem
                                    button
                                    component="a"
                                    href={`${publicUrl}imatges/${item.pdf}`}
                                    target="_blank"
                                >
                                    <ListItemIcon>
                                        <GetApp />
                                    </ListItemIcon>
                                    <ListItemText
                                        primary={
                                            item.nom +
                                            " - " +
                                            moment(item.created_at).format(
                                                "MMMM YYYY"
                                            )
                                        }
                                        secondary={moment(
                                            item.created_at
                                        ).format("D/M/Y")}
                                    />
                                </StyledListItem>
                            ))}
                        </div>
                    </Fade>
                ) : (
                    <Loading />
                )}
            </List>
        </Box>
    );
}
