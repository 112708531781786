import * as React from "react";
import { Box, TextField, styled } from "@material-ui/core";

const CssTextField = styled(TextField)({
	"& .MuiInputBase-root": {},
	"& input": {
		fontFamily: "Varela Round",
		color: "#383838",
	},
	"& textarea": {
		color: "#d9594e",
	},
	"& label.Mui-focused": {
		color: "#d9594e",
	},
	"& label": {
		fontFamily: "Varela Round",
		color: "#d9594e",
	},
	"& .MuiInput-underline:after": {
		borderBottomColor: "#d9594e",
	},
	"& .MuiOutlinedInput-root": {
		borderRadius: 10,
		"& fieldset": {
			borderWidth: 2,
			borderColor: "#d9594e",
		},
		"&:hover fieldset": {
			borderWidth: 3,
			borderColor: "#d9594e",
		},
		"&.Mui-focused fieldset": {
			borderWidth: 3,
			borderColor: "#d9594e",
		},
	},
});

export default function CustomTextField({ errors, label, name, type, register, size, light, adornment, endAdornment, mt, ...rest }) {
	return (
		<Box mt={mt} style={{ width: "100%" }}>
			{light ? (
				<CssTextField
					error={Boolean(errors?.[name])}
					helperText={errors?.[name]?.message}
					fullWidth
					label={label}
					name={name}
					type={type}
					variant="outlined"
					{...register(name)}
					{...rest}
				/>
			) : (
				<CssTextField
					error={Boolean(errors?.[name])}
					helperText={errors?.[name]?.message}
					fullWidth
					label={label}
					name={name}
					type={type}
					size={size === "small" ? "small" : "medium"}
					variant="outlined"
					InputProps={{
						startAdornment: adornment,
						endAdornment: endAdornment,
					}}
					{...register(name)}
					{...rest}
				/>
			)}
		</Box>
	);
}
