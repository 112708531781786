import React, { useEffect } from "react";
import Portada from "./elements/Portada";
import QueEsInici from "./elements/QueEsInici";
import Protagonistes from "./elements/Protagonistes";
import Noticies from "./elements/NoticiesInici";
import Agenda from "./elements/AgendaInici";
import Footer from "../../components/Footer";
import Page from "../../components/Page";
import SeparatorInici from "../../components/SeparatorInici";
import { useTranslation } from "react-i18next";

const Home = () => {
    const { t } = useTranslation();
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    return (
        <Page title={t("Inici")}>
            <Portada />
            <Noticies />
            <SeparatorInici />
            <QueEsInici />
            <SeparatorInici />
            <Protagonistes />
            <SeparatorInici />
         
            <Agenda />
            <Footer />
        </Page>
    );
};

export default Home;
