import {
    Box,
    Container,
    Grid,
    makeStyles,
    Typography,
    useMediaQuery,
} from '@material-ui/core';
import React, { useEffect } from 'react';
import ScrollAnimation from 'react-animate-on-scroll';
import { useTranslation } from 'react-i18next';
import Header from '../components/Header';
import Page from '../components/Page';
import Footer from '../components/Footer';
import Cronologia from '../components/Cronologia/Cronologia';
import Divide from '../components/Divide';
import MyButtonMini from '../components/MyButtonMini';

const useStyles = makeStyles((theme) => ({
    root: {
        marginTop: 100,
    },
    main: {
        paddingTop: 100,
        paddingBottom: 200,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        backgroundColor: theme.palette.background.default,
        alignContent: 'center',
    },
    mainM: {
        paddingTop: 50,
        backgroundColor: theme.palette.background.default,
        paddingBottom: 200,
    },
    image: {
        width: '100%',
    },
}));
const Historia = () => {
    const classes = useStyles();
    const { t } = useTranslation();
    const matches = useMediaQuery('(min-width:960px)');

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <Page title={t('Història de La Patum')} description={t('historia_1')}>
            <Box className={matches ? classes.main : classes.mainM}>
                <ScrollAnimation
                    animateIn="fadeIn"
                    style={{ transition: '0.5s' }}
                >
                    <Container className={classes.root}>
                        <Header title={t('Història')} subtitle={t('Orígens')} />
                        <Grid container spacing={10}>
                            <Grid item md={6}>
                                <Box pb={3}>
                                    <Typography
                                        variant="h3"
                                        style={{ color: 'white' }}
                                    >
                                        {t('historia_1')}
                                    </Typography>
                                </Box>
                                <Box py={1}>
                                    <Typography
                                        variant="body1"
                                        style={{
                                            color: '#FFFFFF',
                                            whiteSpace: 'pre-line',
                                        }}
                                    >
                                        {t('historia_2')}
                                    </Typography>
                                </Box>
                                <Cronologia />
                                <Box style={{ textAlign: 'center' }} mt={2}>
                                    <MyButtonMini
                                        title={t('Cronologia sencera')}
                                        href={`${process.env.PUBLIC_URL}/documents/LiniaTempsPatum.pdf`}
                                        target="_blank"
                                    />
                                </Box>
                            </Grid>
                            <Grid item md={6}>
                                <Box mb={3}>
                                    <img
                                        alt="Historia"
                                        src={`${process.env.PUBLIC_URL}/images/historia_1.jpg`}
                                        className={classes.image}
                                    />
                                </Box>
                                <Box mb={3}>
                                    <img
                                        alt="Historia"
                                        src={`${process.env.PUBLIC_URL}/images/historia_2.jpg`}
                                        className={classes.image}
                                    />
                                </Box>
                                <Box mb={3}>
                                    <img
                                        alt="Historia"
                                        src={`${process.env.PUBLIC_URL}/images/historia_3.jpg`}
                                        className={classes.image}
                                    />
                                </Box>
                                <Box mb={3}>
                                    <img
                                        alt="Historia"
                                        src={`${process.env.PUBLIC_URL}/images/historia_4.jpg`}
                                        className={classes.image}
                                    />
                                </Box>
                                <Box mb={3}>
                                    <img
                                        alt="Historia"
                                        src={`${process.env.PUBLIC_URL}/images/historia_5.jpg`}
                                        className={classes.image}
                                    />
                                </Box>
                            </Grid>
                        </Grid>
                        <Box my={5}>
                            <Divide />
                        </Box>
                        <Box my={5}>
                            <Typography variant="h2" style={{ color: 'white' }}>
                                UNESCO
                            </Typography>
                        </Box>
                        <Box my={1}>
                            <Typography variant="h3" style={{ color: 'white' }}>
                                {t('unesco_titol')}
                            </Typography>
                        </Box>
                        <Box my={5}>
                            <Typography
                                variant="body1"
                                style={{
                                    color: '#FFFFFF',
                                    whiteSpace: 'pre-line',
                                }}
                            >
                                {t('unesco')}
                            </Typography>
                        </Box>
                    </Container>
                </ScrollAnimation>
            </Box>
            <Footer />
        </Page>
    );
};

export default Historia;
