import React from "react";
import { withStyles } from "@material-ui/styles";
import { Box, Button, CircularProgress, makeStyles, Typography } from "@material-ui/core";
import { useNavigate } from "react-router";

const BootstrapButton = withStyles(
	(theme) => ({
		root: {
			boxShadow: "none",
			textTransform: "none",
			fontSize: 16,
			borderRadius: 30,
			border: "2px solid",
			borderColor: theme.palette.background.default,
			lineHeight: 1.5,
			backgroundColor: theme.palette.background.default,
			transition: "0.5s",
			"& .MuiTypography-h3": {
				color: "white",
			},
			"& .MuiTypography-body1": {
				color: "white",
			},
			padding: 15,
			"&:hover": {
				color: "white",
				border: "2px solid",
				borderColor: theme.palette.background.default,
				backgroundColor: "transparent",
				boxShadow: "none",
				"& .MuiTypography-h3": {
					color: theme.palette.background.default,
				},
				"& .MuiTypography-body1": {
					color: theme.palette.background.default,
				},
			},
			"&:active": {
				boxShadow: "none",
				backgroundColor: theme.palette.background.default,
				borderColor: theme.palette.background.default,
			},
			"&:focus": {
				boxShadow: "0 0 0 0.1rem ",
			},
		},
		label: {
			color: "white",
		},
	}),
	{ withTheme: true }
)(Button);

const useStyles = makeStyles((theme) => ({
	margin: {
		paddingLeft: 40,
		paddingRight: 40,
	},
	button: {
		flexDirection: "columns",
	},
}));

export default function CustomButton({ onClick, title, loading, ...rest }) {
	const classes = useStyles();

	return (
		<div>
			<BootstrapButton variant="contained" color="primary" disableRipple className={classes.margin} onClick={onClick} {...rest}>
				<Box className={classes.button}>
					{loading ? <CircularProgress color="white" style={{ width: 20, height: 20 }} /> : <Typography variant="body1">{title}</Typography>}
				</Box>
			</BootstrapButton>
		</div>
	);
}
