import {
    Box,
    Container,
    makeStyles,
    Typography,
    useMediaQuery,
} from "@material-ui/core";
import React, { useEffect, useState } from "react";
import ScrollAnimation from "react-animate-on-scroll";
import { useTranslation } from "react-i18next";
import Header from "../../components/Header";
import Page from "../../components/Page";
import Footer from "../../components/Footer";
import { Element } from "react-scroll";
import i18n from "../../lang";
import { getConsells } from "../../database/API";
import Loading from "../../components/Loading";

const useStyles = makeStyles((theme) => ({
    root: {
        marginTop: 100,
    },
    main: {
        paddingTop: 100,
        paddingBottom: 100,
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        backgroundColor: theme.palette.background.default,
        alignContent: "center",
    },
    mainM: {
        paddingTop: 50,
        paddingBottom: 50,
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        backgroundColor: theme.palette.background.default,
        alignContent: "center",
    },
    main2: {
        paddingBottom: 200,
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        backgroundColor: "white",
        alignContent: "center",
    },
    icon: {
        cursor: "pointer",
        display: "flex",
        paddingRight: 15,
    },
    buttons: {
        display: "flex",
        flexWrap: "wrap",
    },
}));
const Consells = () => {
    const classes = useStyles();
    const { t } = useTranslation();
    const [consells, setConsells] = useState();
    const [loading, setLoading] = useState(true);
    const matches = useMediaQuery("(min-width:960px)");

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    useEffect(() => {
        const get = async () => {
            const { data } = await getConsells(i18n.language);
            setConsells(data);
            setLoading(false);
        };
        get();
    }, [t]);

    return (
        <Page title={t("Consells")}>
            <Box className={matches ? classes.main : classes.mainM}>
                <ScrollAnimation
                    animateIn="fadeIn"
                    style={{ transition: "0.5s" }}
                >
                    <Container className={classes.root}>
                        <Box>
                            <Header title={t("Consells")} />
                        </Box>
                    </Container>
                </ScrollAnimation>
            </Box>
            <Element name="content" className={classes.main2}>
                <ScrollAnimation
                    animateIn="fadeIn"
                    style={{ transition: "0.5s" }}
                >
                    {!loading ? (
                        <Container className={classes.root}>
                            {consells?.map((item) => {
                                return (
                                    <Box>
                                        <Typography variant="h3">
                                            {item.titol}
                                        </Typography>
                                        <Typography variant="body1">
                                            {item.descripcio}
                                        </Typography>
                                        <Box my={3} />
                                    </Box>
                                );
                            })}
                        </Container>
                    ) : (
                        <Box
                            style={{
                                width: "100%",
                                height: 300,
                                display: "flex",
                                justifyContent: "center",
                                alignContent: "center",
                                textAlign: "center",
                                alignItems: "center",
                            }}
                        >
                            <Loading />
                        </Box>
                    )}
                </ScrollAnimation>
            </Element>
            <Footer />
        </Page>
    );
};

export default Consells;
