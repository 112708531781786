import React from "react";
import { Box, Button, Grid, makeStyles, Typography, useMediaQuery } from "@material-ui/core";
import { ReactComponent as Rodo } from "../theme/icons/logo-rodo.svg";
import { ReactComponent as Ajuntament } from "../theme/icons/logo-ajuntament.svg";
import { ReactComponent as Patrimoni } from "../theme/icons/patrimoni.svg";
import { ReactComponent as Apple } from "../theme/icons/apple.svg";
import { ReactComponent as Google } from "../theme/icons/google.svg";
import { ReactComponent as Cultura } from "../theme/icons/cultura.svg";
import { ReactComponent as Consell } from "../theme/icons/consell.svg";
import { ReactComponent as Vera } from "../theme/icons/vera.svg";
import { ReactComponent as Xarxa } from "../theme/icons/xarxa.svg";
import { ReactComponent as Diputacio } from "../theme/icons/diputacio.svg";

import Logo from "./Logo";
import { useNavigate } from "react-router";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles((theme) => ({
	root: {
		padding: 65,
		paddingTop: 125,
	},
	rootM: {
		padding: 30,
	},
	main: {
		zIndex: 10,
		display: "flex",
		flexDirection: "column",
		justifyContent: "center",
		backgroundColor: theme.palette.background.dark,
		alignContent: "center",
		position: "relative",
	},
	text: {
		color: theme.palette.text.primary,
		fontSize: 14,
	},
	title: {
		color: theme.palette.text.primary,
		fontSize: 18,
	},
	senefa: {
		marginTop: -50,
		padding: 25,
		backgroundSize: "contain",
		backgroundPosition: "center",
		backgroundImage: `url("${process.env.PUBLIC_URL}/images/senefa.png")`,
	},
	logos: {
		justifyContent: "space-between",
		alignContent: "center",
		width: "100%",
		marginBottom: 20,
	},
	button: {
		backgroundColor: theme.palette.text.primary,
		borderRadius: 10,
		marginRight: 6,
	},
	botons: {
		justifyContent: "space-between",
	},
}));

const Footer = ({ className, onMobileNavOpen, ...rest }) => {
	const classes = useStyles();
	const matches = useMediaQuery("(min-width:960px)");
	const navigate = useNavigate();
	const { t } = useTranslation();

	return (
		<Box className={classes.main}>
			<Box className={classes.senefa} />

			<div className={matches ? classes.root : classes.rootM}>
				<Grid container spacing={10}>
					<Grid item xl={5} lg={5}>
						<Grid container spacing={5} style={{ alignItems: "center" }}>
							<Grid item>
								<Typography variant="h2" className={classes.title}>
									{t("Contacte")}
								</Typography>
								<Box my={2} />

								<Typography variant="body1" className={classes.text}>
									Ajuntament de Berga
								</Typography>
								<Typography variant="body1" className={classes.text}>
									{t("Telèfon")} 93 821 43 33
								</Typography>
								<Typography variant="body1" className={classes.text}>
									{t("Correu electrònic")}{" "}
									<a href="maito:festes@ajberga.cat" style={{ color: "#d9594e" }}>
										festes@ajberga.cat
									</a>
								</Typography>
								<Typography variant="body1" className={classes.text}>
									{t("Correu electrònic premsa")}{" "}
									<a href="maito:comunicacio@ajberga.cat" style={{ color: "#d9594e" }}>
										comunicacio@ajberga.cat
									</a>
								</Typography>
								<Box my={2} />
								<Typography variant="body1" className={classes.text}>
									Disseny original del logo i la iconografia de La Patum: Salvador Vinyes Reig
								</Typography>
								<Typography variant="body1" className={classes.text}>
									{t("Disseny i programació")}: La Padrina
								</Typography>
							</Grid>
						</Grid>
					</Grid>
					<Grid item xl={2} lg={2} xs={12}>
						<Logo icon={Rodo} width="100%" height={200} color="#d9594e" />
					</Grid>
					<Grid lg />

					<Grid item xl={4} lg={5}>
						<Grid container spacing={7}>
							<Grid item md={4} xs={12} alignContent="center">
								<Logo icon={Ajuntament} width="100%" height="100%" color="#d9594e" viewBox={"0 0 24 12"} />
							</Grid>
							<Grid item md={4} xs={12} alignContent="center">
								<Logo icon={Patrimoni} width="100%" height="100%" color="#d9594e" viewBox={"0 0 26 19"} />
							</Grid>
							<Grid item md={4} xs={12} alignContent="center">
								<Logo icon={Consell} width="90%" height="100%" color="#d9594e" viewBox={"0 0 500 300"} />
							</Grid>
						</Grid>
						<Grid container className={classes.botons}>
							<Grid item md={6} xs={12} alignContent="center">
								<Logo icon={Cultura} width="90%" height="100%" color="#d9594e" viewBox={"0 0 26 5"} />
							</Grid>
							<Grid md={2} />
							<Grid item md={4} xs={12} alignContent="center">
								<Logo icon={Diputacio} width="90%" height="100%" color="#d9594e" viewBox={"0 0 500 250"} />
							</Grid>
						</Grid>
						<Box mt={3} />
						<Grid container className={classes.botons}>
							<Grid item md={4} xs={12} alignContent="center">
								<Typography variant="body1" className={classes.text}>
									Patrocinador:
								</Typography>
								<a href="https://www.somvera.cat/" alt="Som Vera" target="_blank" rel="noreferrer">
									<Logo icon={Vera} width="90%" height="100%" color="#d9594e" viewBox={"0 0 550 300"} />
								</a>
							</Grid>
							<Grid item md={4} xs={12} alignContent="center">
								<a
									href="https://laxarxames.cat/patum?utm_source=ajuntament_berga&utm_medium=web_banner&utm_campaign=patum2024&utm_id=patum2024"
									alt="Xarxa"
									target="_blank"
									rel="noreferrer"
								>
									<Logo icon={Xarxa} width="90%" height="100%" color="#d9594e" viewBox={"0 0 1900 600"} />
								</a>
							</Grid>
							<Grid item md={4} xs={12} alignContent="center">
								<Box
									style={{
										display: "flex",
										justifyContent: "flex-end",
									}}
								>
									<Box mr={2} component="a" target="_blank" href="https://apps.apple.com/us/app/la-patum/id1464512302">
										<Logo icon={Apple} width={"100%"} height={"100%"} color="#d9594e" viewBox="0 0 24 14" />
									</Box>
									<Box component="a" target="_blank" href="https://play.google.com/store/apps/details?id=com.lapadrina.lapatum&hl=es&gl=US">
										<Logo icon={Google} width={"100%"} height={"100%"} color="#d9594e" viewBox="0 0 24 14" />
									</Box>
								</Box>
							</Grid>
						</Grid>
					</Grid>
				</Grid>
			</div>
		</Box>
	);
};

export default Footer;
