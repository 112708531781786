import { Box, Collapse, Container, Grid, makeStyles, TextField, Typography, useMediaQuery } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import ScrollAnimation from "react-animate-on-scroll";
import { useTranslation } from "react-i18next";
import Header from "../../components/Header";
import Page from "../../components/Page";
import Footer from "../../components/Footer";
import { useSpring } from "@react-spring/core";
import { animated } from "@react-spring/web";
import { Element } from "react-scroll";
import CustomButton from "../../components/CustomButton";
import { useForm } from "react-hook-form";
import CustomTextField from "../../components/CustomTextField";
import CustomCheckbox from "../../components/CustomCheckbox";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { addInscrit, comrpovarInscrit } from "../../database/API";
import { useSnackbar } from "notistack";
import moment from "moment";
import { Alert } from "@material-ui/lab";

const Sorteig = () => {
	const classes = useStyles();
	const { t } = useTranslation();
	const [form, setForm] = useState(false);
	const [loading, setLoading] = useState(false);
	const [acabat, setAcabat] = useState(false);
	const [comp, setComp] = useState(false);
	const [dni, setDni] = useState("");
	const [uniqueId, setUniqueId] = useState("");

	const { enqueueSnackbar } = useSnackbar();

	const [{ opacity }, set] = useSpring(() => ({
		opacity: 1,
		onRest: () => {
			setTimeout(set.start({ opacity: 1 }), 2000);
		},
	}));

	const matches = useMediaQuery("(min-width:960px)");

	useEffect(() => {
		window.scrollTo(0, 0);
	}, []);

	const {
		register,
		handleSubmit,
		watch,
		setValue,
		control,
		reset,
		formState: { errors },
	} = useForm({ resolver: yupResolver(schema) });

	const enviar = async (values) => {
		setLoading(true);
		console.log(values);
		try {
			const { message, variant } = await addInscrit(values);
			enqueueSnackbar(message, {
				variant: variant,
			});
			if (variant === "success") {
				setForm(false);
				reset();
			}
		} catch (error) {
			enqueueSnackbar("Alguna cosa no ha anat bé...", {
				variant: "error",
			});
		}
		setLoading(false);
	};

	useEffect(() => {
		// console.log(moment("2024-04-22 12:38").diff(moment(), "minutes"));
		setAcabat(moment("2024-04-22 00:00").diff(moment(), "minutes"));
	}, []);

	const comprovar = async () => {
		setLoading(true);
		try {
			const { message, variant, unique } = await comrpovarInscrit({ dni: dni });
			setUniqueId(unique);

			enqueueSnackbar(message, {
				variant: variant,
			});
			if (variant === "success") {
				setForm(false);
				reset();
			}
		} catch (error) {
			enqueueSnackbar("Alguna cosa no ha anat bé...", {
				variant: "error",
			});
		}
		setLoading(false);
	};

	return (
		<Page title={t("sorteig")} description={t("vides")}>
			<Box className={matches ? classes.main : classes.mainM}>
				<ScrollAnimation animateIn="fadeIn" style={{ transition: "0.5s" }}>
					<Container className={classes.root}>
						<Box>
							<Header title={"Sortejos de La Patum"} />
						</Box>
					</Container>
				</ScrollAnimation>
			</Box>
			<Element name="content" className={classes.main2}>
				<ScrollAnimation animateIn="fadeIn">
					<Container className={classes.root}>
						<animated.div style={{ opacity }}>
							<Box mb={10}>
								<Typography variant="body1">
									L'Ajuntament de Berga fomenta la participació en La Patum a través de sortejos per accedir al balcó consistorial per
									contemplar els actes de la festa i sortejos de salts de la comparseria patumaire.
								</Typography>
								<Box mt={2} />
								<Typography variant="body1">
									D'una banda, l'any 2018 es va posar en marxa la iniciativa d'obrir el balcó consistorial per tal que la ciutadania pogués
									veure la festa des d'un dels emplaçaments més emblemàtics de la plaça de Sant Pere. El consistori posa a disposició de la
									ciutadania places al balcó de l'ajuntament per veure les sortides i arribades de les passades de dimecres i dissabte, La
									Patum completa de dijous i diumenge (3r i 4t salt), La Patum Infantil (3r i 4t salt) i La Patum de la Llar.
								</Typography>
								<Box mt={2} />
								<Typography variant="body1">
									D'altra banda, la comparseria posa a disposició de la ciutadania diferents salts de Patum per facilitar la participació en
									el Corpus berguedà. En concret, se sortegen salts d'àngels, maces de lluïment i plens.
								</Typography>
							</Box>
							<Grid container spacing={4}></Grid>
							<CustomButton
								title={"Comprovar inscripció"}
								onClick={() => {
									setComp((prev) => !prev);
									setForm(false);
								}}
							/>
							{acabat > 0 ? (
								<Box display={"flex"} mt={3}>
									<CustomButton
										title={"Participar"}
										onClick={() => {
											setForm((prev) => !prev);
											setComp(false);
										}}
									/>
									<Box m={3} />
								</Box>
							) : (
								<Box display={"flex"} mt={3}>
									<Alert severity="warning">Inscripcions tancades</Alert>
								</Box>
							)}
						</animated.div>
						<Collapse in={comp}>
							<Box py={4}>
								<TextField
									fullWidth
									value={dni}
									label="Entra el DNI per comprovar si hem rebut i guardat la inscripció"
									onChange={(val) => setDni(val.target.value)}
								/>
								<Box my={2} />
								<CustomButton title={"Comprovar"} onClick={comprovar} loading={loading} />
								{uniqueId && (
									<Box display={"flex"} mt={3}>
										<Alert severity="success">Codi únic: {uniqueId}</Alert>
									</Box>
								)}
							</Box>
						</Collapse>
						{acabat > 0 && (
							<>
								<Collapse in={form}>
									<Box pt={5}>
										<Typography variant="h2">Formulari d'inscripció</Typography>
										<form onSubmit={handleSubmit(enviar)}>
											<Box pt={3}>
												<Grid container spacing={3}>
													<Grid item md={4} xs={12}>
														<CustomTextField register={register} name={"dni"} label={"DNI"} errors={errors} />
													</Grid>
													<Grid item md={4} xs={12}>
														<CustomTextField register={register} name={"nom"} label={"Nom"} errors={errors} />
													</Grid>
													<Grid item md={4} xs={12}>
														<CustomTextField register={register} name={"cognoms"} label={"Cognoms"} errors={errors} />
													</Grid>
												</Grid>
											</Box>
											<Box pt={3}>
												<Grid container spacing={3}>
													<Grid item md={6} xs={12}>
														<CustomTextField register={register} name={"email"} label={"E-mail"} errors={errors} />
													</Grid>
													<Grid item md={6} xs={12}>
														<CustomTextField register={register} name={"telefon"} label={"Telèfon"} errors={errors} />
													</Grid>
												</Grid>
											</Box>
											<Box pt={3} pb={3}>
												<Grid container spacing={3}>
													<Grid item md={4} xs={12}>
														<CustomCheckbox control={control} label={"Salts de Maces"} name={"maces"} setValue={setValue} />
													</Grid>
													<Grid item md={4} xs={12}>
														<CustomCheckbox control={control} label={"Salts d'Àngels"} name={"angels"} setValue={setValue} />
													</Grid>
													<Grid item md={4} xs={12}>
														<CustomCheckbox control={control} label={"Salt de Plens"} name={"plens"} setValue={setValue} />
													</Grid>
												</Grid>
												<Grid container spacing={3}>
													<Grid item md={4} xs={12}>
														<CustomCheckbox
															control={control}
															label={
																<Box mt={1}>
																	<Typography style={{ lineHeight: 0.5 }}>Balcó Patum completa</Typography>
																	<Typography style={{ lineHeight: 0 }} variant="caption">
																		3er i 4rt salt de dijous i diumenge
																	</Typography>
																</Box>
															}
															name={"balco"}
															setValue={setValue}
														/>
													</Grid>
													<Grid item md={4} xs={12}>
														<CustomCheckbox
															control={control}
															label={
																<Box mt={1}>
																	<Typography style={{ lineHeight: 0.5 }}>Balcó Patum Infantil</Typography>
																	<Typography style={{ lineHeight: 0 }} variant="caption">
																		3r i 4rt salt
																	</Typography>
																</Box>
															}
															name={"balco_infantil"}
															setValue={setValue}
														/>
													</Grid>
													<Grid item md={4} xs={12}>
														<CustomCheckbox
															control={control}
															label={
																<Box mt={1}>
																	<Typography style={{ lineHeight: 0.5 }}>Balcó Patum de la Llar</Typography>
																	<Typography style={{ lineHeight: 0 }} variant="caption">
																		Dimarts a la tarda
																	</Typography>
																</Box>
															}
															name={"balco_llar"}
															setValue={setValue}
														/>
													</Grid>
												</Grid>
											</Box>
											<Typography variant="caption" style={{ color: "red" }}>
												{errors?.unCheck?.message}
											</Typography>
											<Collapse in={watch("plens")}>
												<Box pt={3} className={classes.extra}>
													<Typography style={{ marginBottom: 10 }}>Informació extra pel Salt de Plens</Typography>
													<CustomCheckbox
														control={control}
														label={<Typography variant="caption">Marca si és la primera vegada que saltes de ple.</Typography>}
														name={"salt_previ"}
														setValue={setValue}
													/>
													<Box my={1} />
													<Grid container spacing={2}>
														<Grid item md={6} xs={12}>
															<CustomTextField
																register={register}
																name={"nom_salt"}
																label={"Nom i cognoms acompanyant de plens"}
																errors={errors}
															/>
														</Grid>
														<Grid item md={6} xs={12}>
															<CustomTextField
																register={register}
																name={"dni_salt"}
																label={"DNI acompanyant de plens"}
																errors={errors}
															/>
														</Grid>
													</Grid>
													<Typography variant="caption">L'acompanyant ha de ser major d'edat</Typography>
												</Box>
											</Collapse>

											<Collapse in={watch("balco_infantil")}>
												<Box pt={3} className={classes.extra}>
													<Typography style={{ marginBottom: 10 }}>Informació extra pel balcó de La Patum Infantil</Typography>
													<Grid container spacing={2}>
														<Grid item md={6} xs={12}>
															<CustomTextField
																register={register}
																name={"nom_infantil"}
																label={"Nom i cognoms de l'infant"}
																errors={errors}
															/>
														</Grid>
														<Grid item md={6} xs={12}>
															<CustomTextField
																register={register}
																name={"dni_infantil"}
																label={"DNI de l'infant"}
																errors={errors}
															/>
														</Grid>
													</Grid>
												</Box>
											</Collapse>

											<Collapse in={watch("balco_llar")}>
												<Box pt={3} className={classes.extra}>
													<Typography style={{ marginBottom: 10 }}>Informació extra pel balcó de La Patum de la Llar</Typography>
													<Grid container spacing={2}>
														<Grid item md={6} xs={12}>
															<CustomTextField
																register={register}
																name={"nom_llar"}
																label={"Nom i cognoms acompanyant al balcó"}
																errors={errors}
															/>
														</Grid>
														<Grid item md={6} xs={12}>
															<CustomTextField
																register={register}
																name={"dni_llar"}
																label={"DNI acompanyant al balcó"}
																errors={errors}
															/>
														</Grid>
													</Grid>
												</Box>
											</Collapse>

											<Collapse in={watch("angels")}>
												<Box pt={3} className={classes.extra}>
													<Typography style={{ marginBottom: 10 }}>Informació extra pel Salt d'Àngels</Typography>
													<Grid container spacing={2}>
														<Grid item md={6} xs={12}>
															<CustomTextField
																register={register}
																name={"nom_angels"}
																label={"Nom i cognoms acompanyant dels àngels"}
																errors={errors}
															/>
														</Grid>
														<Grid item md={6} xs={12}>
															<CustomTextField
																register={register}
																name={"dni_angels"}
																label={"DNI acompanyant dels àngels"}
																errors={errors}
															/>
														</Grid>
													</Grid>
													<Typography variant="caption">L'acompanyant ha de ser major d'edat</Typography>
												</Box>
											</Collapse>

											<Collapse in={watch("balco")}>
												<Box pt={3} className={classes.extra}>
													<Typography style={{ marginBottom: 10 }}>Informació extra pel balcó La Patum completa</Typography>
													<Grid container spacing={2}>
														<Grid item md={6} xs={12}>
															<CustomTextField
																register={register}
																name={"nom_balco"}
																label={"Nom i cognoms acompanyant al balcó"}
																errors={errors}
															/>
														</Grid>
														<Grid item md={6} xs={12}>
															<CustomTextField
																register={register}
																name={"dni_balco"}
																label={"DNI acompanyant al balcó"}
																errors={errors}
															/>
														</Grid>
													</Grid>
													<Typography variant="caption">L'acompanyant ha de ser major d'edat</Typography>
												</Box>
											</Collapse>

											<Box mt={3} mb={3}>
												<CustomCheckbox
													control={control}
													label={
														<Typography variant="caption">
															He llegit les{" "}
															<a href="https://www.lapatum.cat/actualitat/noticies/109" target="_blank" rel="noreferrer">
																Condicions del sorteig de salts
															</a>{" "}
															i les{" "}
															<a href="https://www.lapatum.cat/actualitat/noticies/108" target="_blank" rel="noreferrer">
																Condicions del sorteig del balcó
															</a>
														</Typography>
													}
													name={"accepta-condicions"}
													setValue={setValue}
													required
												/>

												<CustomCheckbox
													control={control}
													label={
														<Typography variant="caption">
															He llegit i accepto la{" "}
															<a href="/politica-privacitat" target="_blank">
																Política de Privacitat
															</a>
														</Typography>
													}
													name={"accepta"}
													setValue={setValue}
													required
												/>
											</Box>
											<CustomButton type="submit" title={"Enviar"} loading={loading} />
										</form>
									</Box>
								</Collapse>
							</>
						)}
					</Container>
				</ScrollAnimation>
			</Element>
			<Footer />
		</Page>
	);
};

export default Sorteig;

const useStyles = makeStyles((theme) => ({
	root: {
		marginTop: 100,
	},
	main: {
		paddingTop: 100,
		paddingBottom: 100,
		display: "flex",
		flexDirection: "column",
		justifyContent: "center",
		backgroundColor: theme.palette.background.default,
		alignContent: "center",
	},
	main2: {
		paddingBottom: 200,
		display: "flex",
		flexDirection: "column",
		justifyContent: "center",
		backgroundColor: "white",
		alignContent: "center",
	},
	mainM: {
		paddingTop: 50,
		display: "flex",
		flexDirection: "column",
		justifyContent: "center",
		backgroundColor: theme.palette.background.default,
		alignContent: "center",
	},
	extra: {
		backgroundColor: "#fafafa",
		padding: 20,
		borderRadius: 10,
		marginTop: 10,
		marginBottom: 10,
	},
}));

export const schema = yup
	.object({
		dni: yup.string().required("El DNI és obligatòri").min(9, "El DNI no té els dígits suficients."),
		nom: yup.string().required("El nom és obligatòri"),
		cognoms: yup.string().required("Els cognoms és obligatòri"),
		telefon: yup.string().required("El telèfon és obligatòri"),
		email: yup.string().email("No és un correu electrònic vàlid").required("La direcció de correu és obligatoria"),

		nom_salt: yup.string().when("plens", {
			is: (plens) => plens,
			then: () => yup.string().required("El nom de l'acompanyant és obligatòri"),
		}),
		dni_salt: yup.string().when("plens", {
			is: (plens) => plens,
			then: () => yup.string().required("El DNI de l'acompanyant és obligatòri").min(9, "El DNI no té els dígits suficients."),
		}),

		// Balcó Completa
		nom_balco: yup.string().when("balco", {
			is: (balco) => balco,
			then: () => yup.string().required("El nom de l'acompanyant és obligatòri"),
		}),
		dni_balco: yup.string().when("balco", {
			is: (balco) => balco,
			then: () => yup.string().required("El DNI de l'acompanyant és obligatòri").min(9, "El DNI no té els dígits suficients."),
		}),

		// Balcó Infantil
		nom_infantil: yup.string().when("balco_infantil", {
			is: (balco_infantil) => balco_infantil,
			then: () => yup.string().required("El nom de l'acompanyant és obligatòri"),
		}),

		// Balcó Llar
		nom_llar: yup.string().when("balco_llar", {
			is: (balco_llar) => balco_llar,
			then: () => yup.string().required("El nom de l'acompanyant és obligatòri"),
		}),

		// Balcó Àngels
		nom_angels: yup.string().when("angels", {
			is: (angels) => angels,
			then: () => yup.string().required("El nom de l'acompanyant és obligatòri"),
		}),
		dni_angels: yup.string().when("angels", {
			is: (angels) => angels,
			then: () => yup.string().required("El DNI de l'acompanyant és obligatòri").min(9, "El DNI no té els dígits suficients."),
		}),
	})
	.test("myCustomTest", null, (obj) => {
		if (obj.plens || obj.balco || obj.maces || obj.angels || obj.balco_infantil || obj.balco_llar) {
			return true; // everything is fine
		}

		return new yup.ValidationError("Has de seleccionar, al menys, una opció", null, "unCheck");
	});
