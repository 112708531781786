import {
    Box,
    Container,
    Grid,
    makeStyles,
    Typography,
    useMediaQuery,
} from '@material-ui/core';
import React, { useEffect, useRef, useState } from 'react';
import ScrollAnimation from 'react-animate-on-scroll';
import { useTranslation } from 'react-i18next';
import Header from '../../components/Header';
import Page from '../../components/Page';
import Footer from '../../components/Footer';
import Logo from '../../components/Logo';
import { ReactComponent as Tabal } from '../../theme/icons/tabal.svg';
import { ReactComponent as Turcs } from '../../theme/icons/turcs.svg';
import { ReactComponent as Maces } from '../../theme/icons/masses.svg';
import { ReactComponent as Angels } from '../../theme/icons/angels.svg';
import { ReactComponent as Guites } from '../../theme/icons/guites.svg';
import { ReactComponent as Aliga } from '../../theme/icons/aliga.svg';
import { ReactComponent as NansVells } from '../../theme/icons/nans-vells.svg';
import { ReactComponent as Gegants } from '../../theme/icons/gegants.svg';
import { ReactComponent as NansNous } from '../../theme/icons/nans-nous.svg';
import { ReactComponent as Plens } from '../../theme/icons/plens.svg';
import { ReactComponent as TabalSol } from '../../theme/icons/tabal-sol.svg';
import { ReactComponent as TurcsSol } from '../../theme/icons/turcs-sol.svg';
import { ReactComponent as MacesSol } from '../../theme/icons/maces-sol.svg';
import { ReactComponent as AngelsSol } from '../../theme/icons/angels-sol.svg';
import { ReactComponent as GuitesSol } from '../../theme/icons/guites-sol.svg';
import { ReactComponent as AligaSol } from '../../theme/icons/aliga-sol.svg';
import { ReactComponent as NansVellsSol } from '../../theme/icons/nans-vells-sol.svg';
import { ReactComponent as GegantsSol } from '../../theme/icons/gegants-sol.svg';
import { ReactComponent as NansNousSol } from '../../theme/icons/nans-nous-sol.svg';
import { ReactComponent as PlensSol } from '../../theme/icons/plens-sol.svg';
import theme from '../../theme';
import Divide from '../../components/Divide';
import ReproductorC from '../../components/ReproductorC';
import ComparsesLlista from '../../components/ComparsesLlista';
import { useSpring } from '@react-spring/core';
import { animated } from '@react-spring/web';
import { Element, animateScroll } from 'react-scroll';
import { useLocation } from 'react-router';

const useStyles = makeStyles((theme) => ({
    root: {
        marginTop: 100,
    },
    main: {
        paddingTop: 100,
        paddingBottom: 100,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        backgroundColor: theme.palette.background.default,
        alignContent: 'center',
    },
    main2: {
        paddingBottom: 200,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        backgroundColor: 'white',
        alignContent: 'center',
    },
    mainM: {
        paddingTop: 50,
        backgroundColor: theme.palette.background.default,
        paddingBottom: 200,
    },
    dossierWrap: {
        padding: 20,
        display: 'flex',
        borderRadius: 30,
        justifyContent: 'space-between',
        alignItems: 'center',
        transition: '0.5s',
        border: '2px solid white',
        '& .MuiTypography-h3': {
            color: 'white',
        },
        '& .MuiSvgIcon-root': {
            color: 'white',
        },
        '&:hover': {
            color: theme.palette.background.default,
            backgroundColor: 'white',
            boxShadow: 'none',
            '& .MuiTypography-h3': {
                color: theme.palette.background.default,
            },
            '& .MuiSvgIcon-root': {
                color: theme.palette.background.default,
            },
        },
    },
    nomWrap: {
        alignItems: 'center',
        display: 'flex',
    },
    abbr: {
        backgroundColor: 'white',
        marginRight: 20,
    },
    icon: {
        cursor: 'pointer',
    },
    image: {
        width: '100%',
    },
    audio: {
        width: '100%',
    },
    onScroll: {
        position: 'fixed',
        top: 84,
        backgroundColor: theme.palette.background.default,
        left: 0,
        zIndex: 10,
        padding: 50,
    },
}));
const QueEs = () => {
    const classes = useStyles();
    const { t } = useTranslation();
    let location = useLocation();
    const [comparsa, setComparsa] = useState({
        icon: TabalSol,
        nom: 'tabal',
        sku: 'tabal',
    });
    const [{ opacity }, set] = useSpring(() => ({
        opacity: 1,
        onRest: () => {
            setTimeout(set.start({ opacity: 1 }), 2000);
        },
    }));
    const matches = useMediaQuery('(min-width:960px)');
    const tabal = useRef(null);
    const turcs = useRef(null);
    const maces = useRef(null);
    const angels = useRef(null);
    const guites = useRef(null);
    const aliga = useRef(null);
    const nansNous = useRef(null);
    const gegants = useRef(null);
    const nansVells = useRef(null);
    const plens = useRef(null);

    const refs = {
        tabal: tabal,
        turcs: turcs,
        maces: maces,
        angels: angels,
        nansNous: nansNous,
        gegants: gegants,
        nansVells: nansVells,
        plens: plens,
    };

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    useEffect(() => {
        console.log(location);
        console.log(refs);
        setTimeout(refs[location?.state]?.current.click(), 1000);
    }, [location]);

    const changeComparsa = async (comp, Icon, sku) => {
        const onRest = () => {
            setComparsa({ icon: Icon, nom: comp, sku: sku });
        };
        set.start({ opacity: 0, onRest });
        animateScroll.scrollTo(matches ? 420 : 650, {
            smooth: true,
            duration: 1000,
        });
    };

    return (
        <Page title={t('Comparses')}>
            <Box className={matches ? classes.main : classes.mainM}>
                {matches && <ComparsesLlista changeComparsa={changeComparsa} />}

                <ScrollAnimation
                    animateIn="fadeIn"
                    style={{ transition: '0.5s' }}
                >
                    <Container className={classes.root}>
                        <Box>
                            <Header title={t('Comparses')} />
                            <Grid container className={classes.buttons}>
                                <Grid
                                    xs={3}
                                    md
                                    className={classes.icon}
                                    onClick={() =>
                                        changeComparsa(
                                            'tabal',
                                            TabalSol,
                                            'tabal'
                                        )
                                    }
                                    ref={tabal}
                                >
                                    <Logo
                                        width={'100%'}
                                        height={80}
                                        icon={Tabal}
                                        color="white"
                                        hover
                                    />
                                </Grid>
                                <Grid
                                    xs={3}
                                    md
                                    className={classes.icon}
                                    ref={turcs}
                                    onClick={() =>
                                        changeComparsa(
                                            'turcs i cavallets',
                                            TurcsSol,
                                            'turcs'
                                        )
                                    }
                                >
                                    <Logo
                                        width={'100%'}
                                        height={80}
                                        icon={Turcs}
                                        color="white"
                                        hover
                                    />
                                </Grid>
                                <Grid
                                    xs={3}
                                    md
                                    className={classes.icon}
                                    onClick={() =>
                                        changeComparsa(
                                            'maces',
                                            MacesSol,
                                            'maces'
                                        )
                                    }
                                    ref={maces}
                                >
                                    <Logo
                                        width={'100%'}
                                        height={80}
                                        icon={Maces}
                                        color="white"
                                        hover
                                    />
                                </Grid>
                                <Grid
                                    xs={3}
                                    md
                                    className={classes.icon}
                                    ref={angels}
                                    onClick={() =>
                                        changeComparsa(
                                            'àngels',
                                            AngelsSol,
                                            'angels'
                                        )
                                    }
                                >
                                    <Logo
                                        width={'100%'}
                                        height={80}
                                        icon={Angels}
                                        color="white"
                                        hover
                                    />
                                </Grid>
                                <Grid
                                    xs={3}
                                    md
                                    className={classes.icon}
                                    ref={guites}
                                    onClick={() =>
                                        changeComparsa(
                                            'guites',
                                            GuitesSol,
                                            'guites'
                                        )
                                    }
                                >
                                    <Logo
                                        width={'100%'}
                                        height={80}
                                        icon={Guites}
                                        color="white"
                                        hover
                                    />
                                </Grid>
                                <Grid
                                    xs={3}
                                    md
                                    className={classes.icon}
                                    ref={aliga}
                                    onClick={() =>
                                        changeComparsa(
                                            'àliga',
                                            AligaSol,
                                            'aliga'
                                        )
                                    }
                                >
                                    <Logo
                                        width={'100%'}
                                        height={80}
                                        icon={Aliga}
                                        color="white"
                                        hover
                                    />
                                </Grid>
                                <Grid
                                    xs={3}
                                    md
                                    className={classes.icon}
                                    ref={nansVells}
                                    onClick={() =>
                                        changeComparsa(
                                            'nans vells',
                                            NansVellsSol,
                                            'nans-vells'
                                        )
                                    }
                                >
                                    <Logo
                                        width={'100%'}
                                        height={80}
                                        icon={NansVells}
                                        color="white"
                                        hover
                                    />
                                </Grid>
                                <Grid
                                    xs={3}
                                    md
                                    className={classes.icon}
                                    ref={gegants}
                                    onClick={() =>
                                        changeComparsa(
                                            'gegants',
                                            GegantsSol,
                                            'gegants'
                                        )
                                    }
                                >
                                    <Logo
                                        width={'100%'}
                                        height={80}
                                        icon={Gegants}
                                        color="white"
                                        hover
                                    />
                                </Grid>
                                <Grid
                                    xs={3}
                                    md
                                    className={classes.icon}
                                    ref={nansNous}
                                    onClick={() =>
                                        changeComparsa(
                                            'nans nous',
                                            NansNousSol,
                                            'nans-nous'
                                        )
                                    }
                                >
                                    <Logo
                                        width={'100%'}
                                        height={80}
                                        icon={NansNous}
                                        color="white"
                                        hover
                                    />
                                </Grid>
                                <Grid
                                    xs={3}
                                    md
                                    className={classes.icon}
                                    ref={plens}
                                    onClick={() =>
                                        changeComparsa(
                                            'plens',
                                            PlensSol,
                                            'plens'
                                        )
                                    }
                                >
                                    <Logo
                                        width={'100%'}
                                        height={80}
                                        icon={Plens}
                                        color="white"
                                        hover
                                    />
                                </Grid>
                            </Grid>
                        </Box>
                    </Container>
                </ScrollAnimation>
            </Box>
            <Element name="content" className={classes.main2}>
                <ScrollAnimation animateIn="fadeIn">
                    <Container className={classes.root}>
                        <animated.div style={{ opacity }}>
                            <Grid container>
                                <Grid item xs={12}>
                                    <Logo
                                        icon={comparsa.icon}
                                        color={theme.palette.text.primary}
                                        height={200}
                                        width={200}
                                    />
                                    <Box py={3} />
                                    <Typography variant="h2">
                                        {comparsa.nom}
                                    </Typography>
                                    <Divide color />

                                    <Box py={3} />
                                </Grid>
                            </Grid>
                            <Grid container spacing={10}>
                                <Grid item md={6}>
                                    <Typography variant="h4">
                                        {t('titol_' + comparsa.sku)}
                                    </Typography>
                                    <Box py={3} />

                                    <Typography
                                        variant="body1"
                                        style={{ whiteSpace: 'pre-line' }}
                                    >
                                        {t('text_' + comparsa.sku)}
                                    </Typography>
                                </Grid>
                                <Grid item md={6}>
                                    <ReproductorC
                                        song={`${process.env.PUBLIC_URL}/sons/${comparsa.sku}.mp3`}
                                    />
                                    <Box py={3} />

                                    <Box py={2}>
                                        <img
                                            alt="Dissabte 1"
                                            src={`${process.env.PUBLIC_URL}/images/c_${comparsa.sku}_1.jpg`}
                                            className={classes.image}
                                        />
                                    </Box>
                                    <Box py={2}>
                                        <img
                                            alt="Dissabte 1"
                                            src={`${process.env.PUBLIC_URL}/images/c_${comparsa.sku}_2.jpg`}
                                            className={classes.image}
                                        />
                                    </Box>
                                </Grid>
                            </Grid>
                        </animated.div>
                    </Container>
                </ScrollAnimation>
            </Element>
            <Footer />
        </Page>
    );
};

export default QueEs;
