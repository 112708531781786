import React from "react";
import {
    Box,
    makeStyles,
    SvgIcon,
    Tooltip,
    Typography,
    useMediaQuery,
} from "@material-ui/core";
import { ReactComponent as Linia } from "../../theme/icons/linia.svg";
import Logo from "../Logo";
import { withStyles } from "@material-ui/styles";

const useStyles = makeStyles((theme) => ({
    root: {
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-around",
        position: "relative",
    },
    linia: {
        position: "absolute",
        top: "25%",
        right: -16,
        zIndex: 0,
    },
    any: {
        position: "absolute",
        top: "20%",
        right: 5,
        zIndex: 0,
        "& .MuiTypography-root": {
            color: "white",
        },
    },
    image: {
        zIndex: 10,
        backgroundColor: theme.palette.background.default,
    },
}));

const LightTooltip = withStyles((theme) => ({
    tooltip: {
        backgroundColor: theme.palette.common.white,
        color: theme.palette.text.secondary,
        boxShadow: "none",
        fontFamily: "Varela Round",
        fontSize: 15,
    },
}))(Tooltip);

const Entrada = (props) => {
    const classes = useStyles();
    const { any, foto, offset, icon, tooltip } = props;
    const matches = useMediaQuery("(min-width:960px)");

    return (
        <LightTooltip title={tooltip} placement="right">
            <Box
                textAlign="center"
                className={classes.root}
                style={{ marginLeft: matches ? offset : -90 }}
            >
                <Box className={classes.image}>
                    {icon ? (
                        <Logo
                            component={icon}
                            height={80}
                            width={80}
                            color="white"
                        />
                    ) : (
                        <img
                            src={`${process.env.PUBLIC_URL}/images/cronologia/${foto}`}
                            alt="imatge"
                            style={{ maxWidth: 100 }}
                        />
                    )}
                </Box>
                <Box className={classes.any}>
                    <Typography style={{ fontSize: matches ? 15 : 10 }}>
                        {any}
                    </Typography>
                </Box>
                <Box className={classes.linia}>
                    <SvgIcon
                        style={{ width: 165, height: 35 }}
                        component={Linia}
                        viewBox="0 0 24 5"
                    />
                </Box>
            </Box>
        </LightTooltip>
    );
};

export default Entrada;
