import {
    Box,
    Container,
    Divider,
    Grid,
    makeStyles,
    Typography,
    useMediaQuery,
} from "@material-ui/core";
import React, { useEffect } from "react";
import ScrollAnimation from "react-animate-on-scroll";
import { useTranslation } from "react-i18next";
import Header from "../../components/Header";
import Page from "../../components/Page";
import Footer from "../../components/Footer";
import ComparsesOrdre from "../../components/ComparsesOrdre";
import ComparsesOrdreSencer from "../../components/ComparsesOrdreSencer";

const useStyles = makeStyles((theme) => ({
    root: {
        marginTop: 100,
    },
    main: {
        paddingTop: 100,
        paddingBottom: 200,
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        backgroundColor: "white",
        alignContent: "center",
    },
    mainM: {
        paddingTop: 50,
        paddingBottom: 200,
    },
    wrap: {
        paddingRight: 40,
    },
    wrap2: {
        paddingLeft: 40,
        paddingRight: 100,
    },
    image: {
        width: "100%",
        position: "relative",
    },
    wrapMobile: {
        paddingTop: 20,
    },
    line: {
        width: 3,
        position: "fixed",
        top: 0,
        backgroundColor: theme.palette.background.default,
        right: "50%",
        height: "100%",
    },
    textComparses: {
        textAlign: "center",
    },
}));
const Dijous = () => {
    const classes = useStyles();
    const { t } = useTranslation();
    const matches = useMediaQuery("(min-width:960px)");
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <Page title={t("Dijous de Corpus")} description={t("dijous_1")}>
            <Box className={matches ? classes.main : classes.mainM}>
                <Divider
                    orientation="vertical"
                    flexItem
                    className={matches && classes.line}
                />

                <ScrollAnimation
                    animateIn="fadeIn"
                    style={{ transition: "0.5s" }}
                >
                    <Container className={classes.root}>
                        <Header
                            title={t("Dia de Corpus (dijous)")}
                            subtitle={t("Patum de Lluïment i Patum completa")}
                            background
                        />
                        <Grid container>
                            <Grid
                                item
                                md={6}
                                className={
                                    matches ? classes.wrap : classes.wrapMobile
                                }
                            >
                                <Typography variant="h4">
                                    {t(
                                        "Patum de lluïment dijous i diumenge al migdia"
                                    )}
                                </Typography>
                                <Box p={1}></Box>

                                <Typography variant="body1">
                                    {t("dijous_1")}
                                </Typography>
                            </Grid>
                            <Grid item md={6}></Grid>
                        </Grid>

                        <ComparsesOrdre />

                        <Grid container>
                            <Box py={5}>
                                <img
                                    alt={t("Dijous de Corpus")}
                                    src={`${process.env.PUBLIC_URL}/images/dijous_1.jpg`}
                                    className={classes.image}
                                />
                            </Box>
                        </Grid>
                        <Box p={2}></Box>

                        <Grid container>
                            <Grid
                                item
                                md={6}
                                className={
                                    matches ? classes.wrap : classes.wrapMobile
                                }
                            >
                                <Typography variant="h4">
                                    {t(
                                        "Patum completa dijous i diumenge vespre"
                                    )}
                                </Typography>
                                <Box p={1}></Box>
                                <Typography variant="body1">
                                    {t("dijous_2")}
                                </Typography>
                                <Box p={1}></Box>
                                <Typography variant="body1">
                                    {t("dijous_3")}
                                </Typography>
                            </Grid>
                            <Grid
                                item
                                md={6}
                                className={
                                    matches ? classes.wrap2 : classes.wrapMobile
                                }
                            >
                                <ComparsesOrdreSencer />
                            </Grid>
                        </Grid>
                        <Grid container>
                            <Box py={8}>
                                <img
                                    alt="Dijous 1"
                                    src={`${process.env.PUBLIC_URL}/images/dijous_2.jpg`}
                                    className={classes.image}
                                />
                            </Box>
                        </Grid>
                    </Container>
                </ScrollAnimation>
            </Box>
            <Footer />
        </Page>
    );
};

export default Dijous;
