import { Grow, IconButton, ThemeProvider } from "@material-ui/core";
import theme from "./theme";
import routes from "./routes";
import { useRoutes, useLocation } from "react-router-dom";
import { SnackbarProvider } from "notistack";
import { createRef } from "react";
import { Close } from "@material-ui/icons";

function App() {
	const routing = useRoutes(routes());
	// let location = useLocation();
	// const analytics = useAnalytics();

	// useEffect(() => {
	//     analytics.page();
	// }, [location]);

	const notistackRef = createRef();
	const onClickDismiss = (key) => () => {
		notistackRef.current.closeSnackbar(key);
	};

	return (
		<SnackbarProvider
			maxSnack={3}
			anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
			TransitionComponent={Grow}
			ref={notistackRef}
			action={(key) => (
				<IconButton onClick={onClickDismiss(key)}>
					<Close style={{ color: "white" }} />
				</IconButton>
			)}
		>
			<ThemeProvider theme={theme}>{routing}</ThemeProvider>
		</SnackbarProvider>
	);
}

export default App;
