import { Box, CircularProgress, makeStyles } from "@material-ui/core";
import React from "react";

const useStyles = makeStyles((theme) => ({
    overlay: {
        flex: 1,
    },
}));

const Loading = (props) => {
    const classes = useStyles();
    return (
        <Box className={classes.overlay}>
            <CircularProgress color={"secondary"} />
        </Box>
    );
};

export default Loading;
