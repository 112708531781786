import React from "react";
import {
    Box,
    Container,
    Grid,
    makeStyles,
    Typography,
    useMediaQuery,
} from "@material-ui/core";
import ScrollAnimation from "react-animate-on-scroll";
import { ReactComponent as Comparses } from "../../../theme/icons/comparses.svg";
import { ReactComponent as Admin } from "../../../theme/icons/administradors.svg";
import { ReactComponent as Musics } from "../../../theme/icons/musics.svg";
import { ReactComponent as PatumInfantil } from "../../../theme/icons/patum-infantil.svg";
import { ReactComponent as Patumaires } from "../../../theme/icons/patumaires.svg";
import Logo from "../../../components/Logo";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router";

const useStyles = makeStyles((theme) => ({
    root: {},
    main: {
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        backgroundColor: theme.palette.background.default,
        alignContent: "center",
        paddingBottom: 100,
    },
    text: {
        textAlign: "center",
    },
}));

const Protagonistes = ({ className, onMobileNavOpen, ...rest }) => {
    const classes = useStyles();
    const { t } = useTranslation();
    const matches = useMediaQuery("(min-width:960px)");
    const navigate = useNavigate();

    return (
        <Box style={{ height: matches && "90vh" }} className={classes.main}>
            <ScrollAnimation animateIn="fadeIn" style={{ transition: "0.5s" }}>
                <Container className={classes.root}>
                    <Box my={8}>
                        <Typography variant="h1" style={{ color: "#fff" }}>
                            {t("Protagonistes")}
                        </Typography>
                    </Box>

                    <Grid container spacing={4} style={{ textAlign: "center" }}>
                        <Grid
                            item
                            xs={12}
                            md
                            style={{ cursor: "pointer" }}
                            onClick={() => navigate("/protagonistes/comparses")}
                        >
                            <Logo
                                width={"100%"}
                                height={200}
                                icon={Comparses}
                                color={"white"}
                                hover
                            />
                            <Box pt={3}>
                                <Typography
                                    variant="h3"
                                    style={{ color: "white" }}
                                >
                                    {t("Comparses")}
                                </Typography>
                            </Box>
                        </Grid>
                        <Grid
                            item
                            xs={12}
                            md
                            style={{ cursor: "pointer" }}
                            onClick={() => navigate("/protagonistes/musica")}
                        >
                            <Logo
                                width={"100%"}
                                height={200}
                                icon={Musics}
                                color="white"
                                hover
                            />
                            <Box pt={3}>
                                <Typography
                                    variant="h3"
                                    style={{ color: "white" }}
                                >
                                    {t("Música")}
                                </Typography>
                            </Box>
                        </Grid>
                        <Grid
                            item
                            xs={12}
                            md
                            style={{ cursor: "pointer" }}
                            onClick={() =>
                                navigate("/protagonistes/administradors")
                            }
                        >
                            <Logo
                                width={"100%"}
                                height={200}
                                icon={Admin}
                                color="white"
                                hover
                            />
                            <Box pt={3}>
                                <Typography
                                    variant="h3"
                                    style={{ color: "white" }}
                                >
                                    {t("Administradors/es")}
                                </Typography>
                            </Box>
                        </Grid>
                        <Grid
                            item
                            xs={12}
                            md
                            style={{ cursor: "pointer" }}
                            onClick={() =>
                                navigate("/protagonistes/patum-infantil")
                            }
                        >
                            <Logo
                                width={"100%"}
                                height={200}
                                icon={PatumInfantil}
                                color="white"
                                hover
                            />
                            <Box pt={3}>
                                <Typography
                                    variant="h3"
                                    style={{ color: "white" }}
                                >
                                    {t("patum_infantil")}
                                </Typography>
                            </Box>
                        </Grid>
                        <Grid
                            item
                            xs={12}
                            md
                            style={{ cursor: "pointer" }}
                            onClick={() =>
                                navigate("/protagonistes/patumaires")
                            }
                        >
                            <Logo
                                width={"100%"}
                                height={200}
                                icon={Patumaires}
                                color="white"
                                hover
                            />
                            <Box pt={3}>
                                <Typography
                                    variant="h3"
                                    style={{ color: "white" }}
                                >
                                    {t("Patumaires")}
                                </Typography>
                            </Box>
                        </Grid>
                    </Grid>
                </Container>
            </ScrollAnimation>
        </Box>
    );
};

export default Protagonistes;
