import { Box, Container, Grid, makeStyles, Typography, useMediaQuery } from "@material-ui/core";
import React, { useEffect } from "react";
import ScrollAnimation from "react-animate-on-scroll";
import { useTranslation } from "react-i18next";
import Header from "../../components/Header";
import Page from "../../components/Page";
import Footer from "../../components/Footer";
import Divide from "../../components/Divide";
import MyButton from "../../components/MyButton";
import MyButtonMini from "../../components/MyButtonMini";

const useStyles = makeStyles((theme) => ({
	root: {
		marginTop: 100,
	},
	main: {
		paddingTop: 100,
		paddingBottom: 200,
		display: "flex",
		flexDirection: "column",
		justifyContent: "center",
		backgroundColor: theme.palette.background.default,
		alignContent: "center",
	},
	mainM: {
		paddingTop: 50,
		backgroundColor: theme.palette.background.default,
		paddingBottom: 200,
	},
	image: {
		width: "100%",
	},
}));
const QueEs = () => {
	const classes = useStyles();
	const { t } = useTranslation();
	const matches = useMediaQuery("(min-width:960px)");
	// const { track, page, identify } = useAnalytics();

	useEffect(() => {
		// page("Què és");
		window.scrollTo(0, 0);
	}, []);

	return (
		<Page title={t("que_es")} description={t("que_es_1")}>
			<Box className={matches ? classes.main : classes.mainM}>
				<ScrollAnimation animateIn="fadeIn" style={{ transition: "0.5s" }}>
					<Container className={classes.root}>
						<Header title={t("que_es")} />
						<Grid container spacing={10}>
							<Grid item md={6}>
								<Box py={3}>
									<Typography variant="h3" style={{ color: "white" }}>
										{t("festa")}
									</Typography>
								</Box>
								<Box py={1}>
									<Typography variant="body1" style={{ color: "white" }}>
										{t("que_es_1")}
									</Typography>
								</Box>
								<Box py={1}>
									<Typography variant="body1" style={{ color: "white" }}>
										{t("que_es_2")}
									</Typography>
								</Box>
								<Box py={3}>
									<Grid container spacing={4}>
										<Grid item md={6}>
											<MyButtonMini title={t("Glossari")} url={"/glossari"} fullWidth />
										</Grid>
										<Grid item md={6}>
											<MyButtonMini title={t("Consells")} url={"/consells"} fullWidth />
										</Grid>
									</Grid>
									<Grid container spacing={4}>
										<Grid item md={12}>
											<MyButtonMini
												title={t("Programa La Patum 2024")}
												href={`${process.env.PUBLIC_URL}/documents/programa2024.pdf`}
												fullWidth
												target="_blank"
											/>
										</Grid>
									</Grid>
								</Box>
								<Box py={3}>
									<Divide />
								</Box>
								<Box>
									<img alt="Cartell" src={`${process.env.PUBLIC_URL}/images/CartellPatum2024.jpeg`} className={classes.image} />
								</Box>
							</Grid>
							<Grid item md={6}>
								<Box mb={3}>
									<MyButton
										title={t("Dimecres de Corpus")}
										subtitle={t("Passacarrers amb salts de Patum i Tirabols")}
										fullWidth={true}
										url={"dimecres"}
									/>
								</Box>
								<Box mb={3}>
									<MyButton
										title={t("Dia de Corpus (dijous)")}
										subtitle={t("Patum de Lluïment i Patum completa")}
										fullWidth={true}
										url={"dijous"}
									/>
								</Box>
								<Box mb={3}>
									<MyButton title={t("Divendres de Corpus")} subtitle={t("patum_infantil")} fullWidth={true} url={"divendres"} />
								</Box>
								<Box mb={3}>
									<MyButton
										title={t("Dissabte de Corpus")}
										subtitle={t("Passacarrers amb salts de Patum i Tirabols")}
										fullWidth={true}
										url={"dissabte"}
									/>
								</Box>
								<Box mb={3}>
									<MyButton
										title={t("Diumenge de Corpus")}
										subtitle={t("Patum de Lluïment i Patum completa")}
										fullWidth={true}
										url={"diumenge"}
									/>
								</Box>
								<Divide />
								<Box py={3}>
									<Typography variant="body1" style={{ color: "white" }}>
										{t("que_es_3")}
									</Typography>
								</Box>
								<Box py={3}>
									<Typography
										variant="h3"
										style={{
											color: "white",
											textDecoration: "underline",
											paddingBottom: 5,
										}}
									>
										{t("diumenge_ascencio")}
									</Typography>
									<Typography
										variant="body1"
										style={{
											color: "white",
										}}
									>
										{t("diumenge_ascencio_1")}
									</Typography>
								</Box>
								<Box py={3}>
									<Typography
										variant="h3"
										style={{
											color: "white",
											textDecoration: "underline",
											paddingBottom: 5,
										}}
									>
										{t("patum_pietat")}
									</Typography>
									<Typography
										variant="body1"
										style={{
											color: "white",
										}}
									>
										{t("patum_pietat_1")}
									</Typography>
								</Box>
								<Box py={3}>
									<Typography
										variant="h3"
										style={{
											color: "white",
											textDecoration: "underline",
											paddingBottom: 5,
										}}
									>
										{t("quatre_fuets")}
									</Typography>
									<Typography
										variant="body1"
										style={{
											color: "white",
										}}
									>
										{t("quatre_fuets_1")}
									</Typography>
								</Box>
								<Box py={3}>
									<Typography
										variant="h3"
										style={{
											color: "white",
											textDecoration: "underline",
											paddingBottom: 5,
										}}
									>
										{t("patum_llar")}
									</Typography>
									<Typography
										variant="body1"
										style={{
											color: "white",
										}}
									>
										{t("patum_llar_1")}
									</Typography>
								</Box>
								<Box py={3}>
									<Typography
										variant="body1"
										style={{
											color: "white",
										}}
									>
										{t("que_es_4")}
									</Typography>
								</Box>
							</Grid>
						</Grid>
					</Container>
				</ScrollAnimation>
			</Box>
			<Footer />
		</Page>
	);
};

export default QueEs;
