import {
    Box,
    Container,
    makeStyles,
    Typography,
    useMediaQuery,
} from "@material-ui/core";
import React, { useEffect } from "react";
import ScrollAnimation from "react-animate-on-scroll";
import { useTranslation } from "react-i18next";
import Header from "../../components/Header";
import Page from "../../components/Page";
import Footer from "../../components/Footer";
import { Element } from "react-scroll";

const useStyles = makeStyles((theme) => ({
    root: {
        marginTop: 100,
    },
    main: {
        paddingTop: 100,
        paddingBottom: 100,
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        backgroundColor: theme.palette.background.default,
        alignContent: "center",
    },
    mainM: {
        paddingTop: 50,
        paddingBottom: 50,
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        backgroundColor: theme.palette.background.default,
        alignContent: "center",
    },
    main2: {
        paddingBottom: 200,
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        backgroundColor: "white",
        alignContent: "center",
    },
    icon: {
        cursor: "pointer",
        display: "flex",
        paddingRight: 15,
    },
    buttons: {
        display: "flex",
        flexWrap: "wrap",
    },
}));
const Glossari = () => {
    const classes = useStyles();
    const { t } = useTranslation();

    const matches = useMediaQuery("(min-width:960px)");

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <Page title={t("Glossari")}>
            <Box className={matches ? classes.main : classes.mainM}>
                <ScrollAnimation
                    animateIn="fadeIn"
                    style={{ transition: "0.5s" }}
                >
                    <Container className={classes.root}>
                        <Box>
                            <Header title={t("Glossari")} />
                        </Box>
                    </Container>
                </ScrollAnimation>
            </Box>
            <Element name="content" className={classes.main2}>
                <ScrollAnimation
                    animateIn="fadeIn"
                    style={{ transition: "0.5s" }}
                >
                    <Container className={classes.root}>
                        <Box my={2}>
                            <Typography variant="h3">
                                {t("Comparsa")}
                            </Typography>

                            <Typography variant="body1">
                                {t("glossari_comparsa")}
                            </Typography>
                        </Box>
                        <Box my={2}>
                            <Typography variant="h3">
                                {t("Passacarrers/passades")}
                            </Typography>

                            <Typography variant="body1">
                                {t("glossari_passacarrers")}
                            </Typography>
                        </Box>
                        <Box my={2}>
                            <Typography variant="h3">{t("Salt")}</Typography>

                            <Typography variant="body1">
                                {t("glossari_salt")}
                            </Typography>
                        </Box>
                        <Box my={2}>
                            <Typography variant="h3">
                                {t("Salt de Plens")}
                            </Typography>

                            <Typography variant="body1">
                                {t("glossari_plens")}
                            </Typography>
                        </Box>
                        <Box my={2}>
                            <Typography variant="h3">{t("Fuet")}</Typography>

                            <Typography variant="body1">
                                {t("glossari_fuet")}
                            </Typography>
                        </Box>
                        <Box my={2}>
                            <Typography variant="h3">{t("Tirabol")}</Typography>

                            <Typography variant="body1">
                                {t("glossari_tirabol")}
                            </Typography>
                        </Box>
                        <Box my={2}>
                            <Typography variant="h3">
                                {t("Barreja i Maumau")}
                            </Typography>

                            <Typography variant="body1">
                                {t("glossari_barreja")}
                            </Typography>
                        </Box>
                    </Container>
                </ScrollAnimation>
            </Element>
            <Footer />
        </Page>
    );
};

export default Glossari;
