import { Box, Container, Typography } from "@material-ui/core";
import React from "react";
import ScrollAnimation from "react-animate-on-scroll";
import { useTranslation } from "react-i18next";
import Page from "../components/Page";
import Footer from "../components/Footer";

export const Politica = () => {
	const { t } = useTranslation();

	return (
		<Page title={t("Política de privacitat")}>
			<ScrollAnimation animateIn="fadeIn">
				<Container style={{ marginTop: 200, marginBottom: 250 }}>
					<Box my={4}>
						<Typography variant="h1">Política de Privacitat</Typography>
					</Box>
					<Typography>
						En compliment del Reglament General de Protecció de Dades i de la Llei orgànica 3/2018, de 5 de desembre, de protecció de dades
						personals i garantia dels drets digitals, Ajuntament de Berga -com a responsable del tractament- garanteix la protecció de la privadesa
						i confidencialitat de les dades personals que es faciliten per a la gestió dels tràmits i sol·licituds, així com la prestació de serveis
						municipals.
					</Typography>
					<Typography>
						Les dades personals que es requereixin seran les estrictament necessàries, adequades i pertinents per a la finalitat per a la qual es
						recullin es conservaran de conformitat amb les disposicions legals i reglamentàries aplicables en cada cas. Ajuntament de Berga ha
						implementat les mesures de seguretat necessàries i adequades als riscos associats al tractament.
					</Typography>
					<Typography>
						Les seves dades no seran cedides a tercers, llevat que sigui necessari per a la prestació del servei sol·licitat de conformitat amb les
						disposicions legals i reglamentàries aplicables en cada moment o el consentiment inequívoc, segons el cas.
					</Typography>
					<Typography style={{ fontSize: 30, marginTop: 20 }}>Drets de les persones interessades</Typography>
					<Typography>
						Les persones interessades poden exercir els drets d’accés, rectificació, supressió, oposició i limitació del tractament sobre alguna de
						les seves dades personals:
					</Typography>
					<Typography>• Presencialment: Plaça de Sant Pere, 1, 08600, Berga (Barcelona)</Typography>
					<Typography>• Mitjans electrònics: berga@ajberga.cat</Typography>
					<Typography style={{ fontSize: 30, marginTop: 20 }}>Informació addicional sobre el tractament de dades personals</Typography>
					<Typography>
						Si desitja conèixer més informació sobre els tractaments de dades personals que porta a terme Ajuntament de Berga, es pot posar en
						contacte per mitjans electrònics (berga@ajberga.cat), o en format paper, ja sigui presencialment o per correu postal a l’adreça següent:
						Plaça de Sant Pere, 1, 08600, Berga (Barcelona)
					</Typography>
					<Typography style={{ fontSize: 30, marginTop: 20 }}>Presentació de reclamacions</Typography>
					<Typography>
						L’Autoritat Catalana de Protecció de Dades (www.apdcat.cat) és l’Organisme Supervisor que vetlla pel compliment de la legislació de
						protecció de dades a l’Administració Pública Catalana. Si es considera que s’ha incomplert algun dels preceptes establerts en aquesta
						normativa s’hi pot dirigir per tal d’interposar-hi una reclamació.{" "}
					</Typography>
					<Typography style={{ fontSize: 30, marginTop: 20 }}>Ubicació App La Patum</Typography>
					<Typography>
						L'aplicació de La Patum requereix de la ubicació de l'usuari per tal de poder determinar on es troba en relació a La Patum i/o el
						recorregut mostrat per pantalla. Les dades de la ubicació de l'usuari no s'utilitzen per a cap altra finalitat que no sigui la de
						informar a l'usuari de la seva ubicació dins de La Patum. Tant a Android com a iOS, la possibilitat de denegar el permís de l'aplicació
						d'utilitzar la ubicació, es pot controlar des de la configuració del mateix dispositiu.
					</Typography>
					<Typography>
						Només la ubicació del dispositiu que emet la posició en directe de La Patum, és guardada a una base de dades externa. La ubicació dels
						usuaris no és mai guardada ni utilitzada fora de l'aplicació. En cas de dubte, poseu-vos en contacte al correu electrònic:
						berga@ajberga.cat
					</Typography>
				</Container>
			</ScrollAnimation>
			<Footer />
		</Page>
	);
};
