import { makeStyles } from "@material-ui/styles";
import { useEffect, useState } from "react";

export default function ImageLoader({ src, alt, ...props }) {
	const classes = useStyles();
	const [imgSrc, setImgSrc] = useState();

	useEffect(() => {
		setImgSrc();
		console.log("loading");
		const img = new Image();
		img.src = src;
		img.onload = () => {
			setImgSrc(src);
			console.log(src);
		};
	}, [src]);

	return <img {...props} {...{ src: imgSrc || src }} alt={alt || ""} className={imgSrc ? classes.loaded : classes.loading} />;
}

const useStyles = makeStyles(() => ({
	loading: {
		filter: "blur(10px)",
		clipPath: "inset(0)",
	},
	loaded: {
		filter: "blur(0px)",
		transition: "filter 0.5s linear",
	},
}));
