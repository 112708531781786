const TRANSLATIONS_ES = {
	que_es: "¿Qué es?",

	Inici: "Inicio",

	Història: "Historia",

	Protagonistes: "Protagonistas",

	festa: "La fiesta de los sentidos y de los sentimientos",

	que_es_1:
		"La Patum es la fiesta más importante de Berga y cuenta con más de 600 años de historia. Se celebra una vez al año durante la festividad de Corpus, entre finales de mayo y finales de junio, ya que al tratarse de una fiesta del ciclo de Pascua su calendario es variable. El baile y el fuego son sus elementos más característicos.",

	que_es_2:
		"Los actos principales se celebran desde el miércoles hasta el domingo de la semana de Corpus en las plazas y calles de Berga. El miércoles y el sábado tienen lugar los pasacalles, y el jueves y el domingo, las patums de Lluïment (al mediodía) y completes (por la noche) en la plaza de Sant Pere. Las comparsas se encargan de la actuación o el baile de cada figura durante la fiesta de La Patum.",

	que_es_3: "También se realizan actos paralelos en varios puntos de la ciudad, como actividades familiares, conciertos jóvenes o exposiciones.",

	vides_de_patum: "Vidas de Patum",

	patum_infantil: "Patum Infantil",

	Actualitat: "Actualidad",

	Notícies: "Notícias",

	Agenda: "Agenda",

	Premsa: "Prensa",

	"Totes les notícies": "Todas las notícias",

	"Dimecres de Corpus": "Miércoles de Corpus",

	"Passacarrers amb salts de Patum i Tirabols": "Pasacalles con salts de Patum y Tirabols",

	"Dia de Corpus (dijous)": "Día de Corpus (jueves)",

	"Patum de Lluïment i Patum completa": "Patum de Lluïment y Patum completa",

	"Divendres de Corpus": "Viernes de Corpus",

	"Dissabte de Corpus": "Sábado de Corpus",

	"Diumenge de Corpus": "Domingo de Corpus",

	"Saber-ne més": "Saber más",

	diumenge_ascencio: "Domingo de la Ascensión",

	diumenge_ascencio_1: "Ple de la Ascensión y salida del Tabal anunciando las fiestas (17 días antes del miércoles de Corpus)",

	patum_pietat: "Patum del barrio de la Pietat",

	patum_pietat_1: "12 días antes del miércoles de Corpus",

	quatre_fuets: "Quatre fuets",

	quatre_fuets_1: "Tradicional prueba de los cohetes que se utilizarán durante La Patum (el domingo previo a la semana de Corpus)",

	patum_llar: "Patum de la Llar",

	patum_llar_1: "Patum que se celebra en la escuela de educación especial Llar Santa Maria de Queralt (martes de la semana de Corpus)",

	que_es_4: "Durante la semana de La Patum las pasadas recorren las calles de Berga (ver plano anual) y las patums se realizan en la plaza de Sant Pere.",

	"Passacarrers migdia dimecres": "Pasacalles mediodía miércoles",

	dimecres_1:
		"El pasacalles del miércoles al mediodía recorre las principales calles del casco antiguo de Berga. El Tabaler dirige la ruta y tras él bailan las dos parejas de Gegants al son de la música de la banda. Este recorrido es seguido por niños y jóvenes, y es el único pasacalles de La Patum en el que no hay fuego.",

	dimecres_2:
		"El pasacalles del miércoles al atardecer recorre las principales calles del casco antiguo de Berga y se detiene en 21 puntos en honor a las autoridades y a los representantes del pueblo. El Tabal dirige la ruta y cada vez que para se hace un salt delante del lugar elegido por cada uno de los concejales del Ayuntamiento. Cada salt consiste en una encendida y un baile de Maces, una encendida de Guites y un baile de Gegants. Entre salt y salt, las comparsas van avanzando por el recorrido bailando al son de la música.",

	dimecres_3:
		"El pasacalles acaba con Tirabols en la plaza de Sant Joan, donde todo el mundo da vueltas en la plaza al son de la música. Después se sube por la calle Mayor al son de la popular melodía “Ella s’ho pensa”, que se interpreta únicamente en esa calle y de forma ininterrumpida. Al llegar a la plaza de Sant Pere, también se hacen Tirabols.",

	dimecres_4: "Las únicas comparsas que participan en el pasacalles son el Tabal, las Maces, las dos Guites y la vieja pareja de Gegants.",

	"Patum de lluïment dijous i diumenge al migdia": "Patum de Lluïment jueves y domingo mediodía",

	dijous_1:
		"Al mediodía tiene lugar La Patum de Lluïment; es La Patum “de gala”, una Patum tranquila que se vive de forma bastante sosegada en cuanto a desenfreno. Todas las comparsas excepto los Plens (solo salen por la noche) están convocadas y salen a interpretar su danza una sola vez cada una y de manera consecutiva. Cuando finaliza esta ronda, y ya han actuado todas las comparsas, se inician los Tirabols, en los que las dos Guites y las dos parejas de Gegants comparten la plaza mientras todos dan vueltas al son de la música.",

	"Ordre d'aparició de les comparses": "",

	"Patum completa dijous i diumenge vespre": "Patum completa jueves y domingo al atardecer",

	dijous_2:
		"La Patum completa es la parte más popular de la fiesta y con mayor afluencia de público. La plaza queda completamente repleta y en el centro se desarrollan los bailes de las comparsas. Las comparsas van saliendo una a una a la plaza y bailan al son del Tabal o de la música mientras la gente salta a su alrededor. La ronda de todas las comparsas se repite cuatro veces, a excepción de los Plens, que solo actúan al final de la segunda y cuarta ronda de comparsas. Cada ronda se llama salt y cada vez que bailan los Plens, se llama salt de Plens. Por lo tanto, en una Patum completa hay dos salts, un salt de Plens, dos salts y un último salt de Plens. Dos repeticiones de la misma estructura.",

	dijous_3:
		"Para acabar La Patum completa, se bailan los Tirabols, momento en el que las dos Guites y las dos parejas de Gegants comparten la plaza mientras todo el mundo da vueltas al son de la música en directo.",

	"Escola de Patumaires": "Escuela de Patumaires",

	"Visita el Web": "Visita la web",

	divendres_1:
		"Aunque la actividad empieza mucho antes con los ensayos diarios durante dos semanas, La Patum Infantil se celebra el viernes de Corpus. Los niños de Berga representan su propia Patum en la plaza de Sant Pere con las figuras en su adaptación infantil.",

	Comparses: "Comparsas",

	Música: "Música",
	"La Música": " La Música",

	"Administradors/es": "Administradores/as",

	Patumaires: "Patumaires",

	"Totes les notícices": "Todas las notícias",

	"Tots els esdeveniments": "Todos los eventos",

	Orígens: "Orígenes",

	historia_1: "Más de 600 años de historia: de obra de teatro medieval a Patrimonio Oral e Inmaterial de la Humanidad",

	historia_2:
		"El origen de La Patum hay que buscarlo en los antiguos entremeses que desfilaban en las procesiones de Corpus Christi y que estaban destinados a educar, moralizar y aleccionar al pueblo para que profundizara en el conocimiento de las Sagradas Escrituras. Sin embargo, con el paso del tiempo, estos entremeses fueron adquiriendo entidad propia y ganaron en popularidad, más por su vertiente lúdica que por su carácter aleccionador.\n\nPoco a poco, fueron perdiendo su sentido original y de esas representaciones quedaron solo las partes más festivas. El jolgorio del séquito procesional fue subiendo de tono, sobre todo antes de la salida de la procesión y también a su fin.\n\nEllo dio paso a unas muestras festivas que acabaron conformando la Bulla o Bullícia del Santíssim Sagrament, antiguo nombre de la fiesta que al final del siglo XIX fue sustituido por el de La Patum.\n\nEste fenómeno, que tuvo lugar en varias aldeas y ciudades de Cataluña, fue extinguiéndose como consecuencia de las prohibiciones promulgadas por los poderes civil, eclesiástico y real, de modo que pocas manifestaciones sobrevivieron al Concilio de Trento (1563), e incluso los entremeses quedaron tocados de muerte por las restrictivas disposiciones del siglo XVIII.\n\nEntre todas las antiguas Bullícies del Santíssim Sagrament que tenían lugar en Cataluña, solo la ciudad de Berga ha sido capaz de mantener y conservar la suya a través de los siglos. Es La Patum.",

	"Cronologia sencera": "Conología entera",
	unesco_titol: "Obra Maestra del Patrimonio Oral e Inmaterial de la Humanidad",

	unesco: "El 25 de noviembre de 2005, un jurado internacional reunido en la sede de la UNESCO en París publicaba la lista de formas de expresión cultural que se convertían en Obras Maestras del Patrimonio Oral e Inmaterial de la Humanidad. Entre las elegidas figuraba La Patum de Berga, por su singularidad y valor artístico e histórico; era la primera manifestación popular que obtenía este reconocimiento en Cataluña y segunda en todo el Estado español después del Misterio de Elche.\n\n La obtención de tal reconocimiento supone para La Patum un elemento más para mantener la esencia de la fiesta, pues la propia UNESCO se ha convertido en una protectora directa de esta forma de expresión cultural.\n\n El reconocimiento de La Patum como Obra Maestra del Patrimonio Oral e Inmaterial de la Humanidad es un mérito colectivo, de todas las personas que han perpetuado la más vivida de nuestras tradiciones. Y lo es también de todas las personas que aman La Patum y se sienten orgullosas de seguir celebrándola, año tras año, en Corpus.",

	titol_tabal: "Es el pregonero de la fiesta. El sonido de su repique, pa-tum, pa-tum, da nombre a la fiesta desde finales del siglo XVIII",

	text_tabal:
		"El Tabal es el único elemento de La Patum que participa en todos y cada uno de los actos de la fiesta. Su presencia es constante y, en cierto modo, puede afirmarse que la preside y dirige.\n\nDocumentado desde 1621 y reconstruido en 1726, fue el único instrumento de La Patum a cuyo ritmo evolucionaban las comparsas hasta mediados del siglo XVII. El Tabal, pues, no tenía ningún simbolismo concreto dentro de las representaciones, sino que era el encargado de dirigir y coordinar las evoluciones de los diferentes entremeses y marcaba el ritmo de cada danza.\n\nA finales del siglo XIX, cuando se empezaron a introducir piezas musicales a la fiesta, el protagonismo del Tabal quedó notablemente desplazado.\n\nAun así, Tabal y Tabaler siguen siendo un elemento imprescindible en La Patum. El domingo de la Ascensión, el Tabal es el encargado de anunciar la inminente llegada de La Patum a toda la población de Berga. Al mediodía de la víspera de Corpus, precede a la passada de los Gegants y, ese mismo día al atardecer (y el siguiente sábado), también encabeza los pasacalles o passades. Actualmente preside desde un lugar privilegiado las representaciones de La Patum en la plaza de Sant Pere y, antiguamente, iba al frente del resto de comparsas de La Patum en las procesiones de Corpus.\n\nY es que si alguna vez en el domingo de la Ascensión no se oyera el repique del Tabal anunciando el acuerdo municipal, ese año no habría Patum.",

	titol_turcs: "Se cree que el baile de los Turcs i Cavallets es uno de los más antiguos de La Patum",

	text_turcs:
		"Aunque pueden derivar de antiguas representaciones paganas de carácter ganadero, actualmente representan la lucha secular entre la Cruz y la Media Luna.\n\nCuatro caballeros cristianos y cuatro turcos a pie simulan una batalla en la que los primeros resultan siempre vencedores.\n\nLa primera referencia que nos llega data de 1621, a pesar de que las figuras actuales fueron construidas en 1890. Hasta 1889, los cavallets de Berga tenían una forma simple, ingenua y rudimentaria: la grupa del caballo era simulada por un aro cubierto de ropa que tapaba las piernas del jinete, una diminuta cabeza de madera delante y una muñeca sentada en la grupa. Los nuevos cavallets, encargados a la casa La Perfección de Barcelona, costaron 200 pesetas (1,20 euros). Curiosamente, los vestidos de los Turcs i Cavallets, comprados en la misma tienda, resultaron más caros que los propios cavallets: 250 pesetas (1,50 euros).\n\nEn 1890 los Turcs i Cavallets dejaron de evolucionar al son del Tabal y pasaron a danzar a los acordes de la música compuesta por Joaquim Serra i Farriols, Quimserra.",

	titol_maces: "La eterna lucha entre el Bien y el Mal",

	text_maces:
		"Esta antigua escena, documentada desde 1621, simboliza la eterna lucha entre el Bien y el Mal, representada a través de la ingenua escenificación de una batalla entre ángeles y demonios.\n\nLa maça consiste en un mango rematado por una caja redonda, que contiene piedras que, al sacudirlas, emiten un sonido característico. Están decoradas con rostros diabólicos y llevan un fuet encima. Las Maces son portadas por unos personajes vestidos de diablos que llevan la cara cubierta con una careta.\n\nDurante su baile, las Maces van evolucionando por la plaza hasta que estalla el fuet. En ese momento, los demonios caen al suelo y los ángeles los rematan con la lanza y la espada.\n\nLas Maces fueron la última comparsa a la que se incorporó música, compuesta por Joan Trullàs en 1963. Esta música solo se interpreta en los salts de Lluïment (al mediodía). Por la noche, las Maces siguen evolucionando, como siempre, al son del Tabal.\n\nAntiguamente, las Maces y los Plens habían formado parte de un único cuadro escénico, el de los demonios.",

	titol_angels: "Los representantes del Bien luchando contra el Mal",

	text_angels:
		"Los Àngels los encontramos siempre relacionados con las Maces porque forman parte del mismo cuadro escénico. Actualmente participan en la escena dos representantes de las fuerzas del Bien: Sant Miquel y otro ángel que lo acompaña.\n\nLos Àngels están documentados desde 1621, cuando solo aparece Sant Miquel. Su ayudante se incorporó definitivamente a la representación en la primera mitad del siglo XIX.\n\nHasta la primera década del siglo XX, el arcángel Sant Miquel lucía una careta, conservada actualmente en el Museo Comarcal de Berga.",

	titol_guites: "Uno de los elementos más primitivos de La Patum",

	text_guites:
		"Antiguamente también habían recibido el nombre de Mulassa, Mulafera y Mulaguita. Es uno de los elementos más antiguos y singulares de la celebración. Junto con los Plens, la Guita es uno de los personajes únicos en el mundo, ya que su forma arcaica de mulassa forma parte de un elemento único dentro del bestiario festivo español y mundial.\n\nTradicionalmente solo había una, la Guita Grossa, de la que tenemos constancia desde 1621.\n\nLas Guites son el elemento de La Patum que ha experimentado menos cambios desde sus orígenes y que conserva formas más primitivas. Aunque no haya cambiado en la forma, sí ha cambiado el número de personas que la llevan, los guitaires. Hasta principios del siglo XIX, solo había dos personas encargadas de llevar la Guita. Esta cifra fue aumentando y en la actualidad veintiocho guitaires acompañan a la Guita Grossa y dieciséis a la Guita Xica.\n\nEl mayor cambio que ha vivido esta comparsa a lo largo de su historia ha sido la introducción de la Guita Xica, popularmente conocida como “Boja”, en 1890, en pleno proceso de impulso de La Patum. Nació en la calle Canals (hoy Marcel·lí Buxadé), en una de las tantas patums de barrio que se celebraban en esa época. Ese año, tras solicitar el correspondiente permiso al Ayuntamiento de Berga, salió a la plaza por sorpresa. Causó entre la población una sensación lo bastante satisfactoria como para que se aceptara y pasara a formar parte de La Patum, junto a la Guita Grossa.\n\nLas Guites son la única comparsa de La Patum que siguen saltando exclusivamente al son del Tabal. A pesar de haber transcurrido más de 400 años desde la primera noticia que tenemos de la Guita, se cree que el salt se mantiene como en sus orígenes.",

	titol_aliga: "El Àliga. Baila la danza más majestuosa y apreciada por la población de Berga",

	text_aliga:
		"El Àliga es, sin duda, el elemento del bestiario festivo catalán que más se diferencia del resto. Es la figura más señorial, aristocrática y distinguida. Antiguamente, el Àliga disfrutaba de una serie de privilegios como el de danzar en el presbiterio de la iglesia o ir delante de la custodia en las procesiones de Corpus.\n\nEl Àliga de La Patum nace más tarde que otros elementos de la fiesta, y su historia representa, todavía hoy, un gran enigma.\n\nEn 1756, el Ayuntamiento de Berga decidió incorporar un águila a las solemnidades de Corpus y encargó construir una al carpintero Ramon Roca. Podría ser la misma, convenientemente restaurada y modificada, la que baila actualmente en La Patum.\n\nSu danza es la de mayor valor coreográfico de toda la representación, y su música, la más distinguida y monumental. Esta extraordinaria partitura presenta muchas similitudes con una danza típica del Renacimiento que podría tener su origen en el siglo XVI, si bien su línea melódica parece derivar de un himno gregoriano. La melodía del Àliga de La Patum de Berga ha llegado a definirse como una de las tres piezas más “geniales” de la música popular catalana.",

	"titol_nans-vells": "Cuatro figuras con sombrero de tres picos y castañuelas",

	"text_nans-vells":
		"Los Gegants y los Nans son los elementos festivos más populares a lo largo de los siglos. Actualmente, en Cataluña, son pocas las poblaciones que no disponen de alguna de estas comparsas, y La Patum de Berga no es una excepción.\n\nLa introducción de los Nans Vells y los Nans Nous en La Patum es bastante moderna. Los Nans Vells datan de 1853 y fueron un regalo de Ferran Moragues i Ubach, primer diputado a Cortes del distrito de Berga, a la localidad de Berga, en una época en que estos personajes estaban de moda.\n\nSe trata de cuatro figuras masculinas, con sombrero de tres picos y largas pelucas, que bailan, tocando las castañuelas, al son de los acordes de melodías populares, las mismas que bailan los Gegants. El hecho de no disponer de música propia parece que puede explicarse, seguramente, por su llegada por sorpresa a Berga, lo que obligó a aprovechar las músicas que ya se interpretaban para animarlos a danzar.",

	titol_gegants: "Los antiguos caudillos musulmanes vencidos",

	text_gegants:
		"El primer Gegant de la fiesta está documentado en 1622, y la primera pareja, en 1695. La tradición popular, sobre todo desde el Romanticismo, los ha relacionado con los antiguos caudillos musulmanes vencidos.\n\nActualmente, La Patum cuenta con dos parejas que bailan juntas: los Vells, estrenados en 1866, que sustituyeron a unos más antiguos, y los Nous, construidos en 1891.\n\nSus bailes son, mayoritariamente, adaptaciones de melodías populares catalanas, aunque los autores de Berga Jaume Sala y Mossèn Marià Miró también compusieron bailes de gigantes.",

	"titol_nans-nous": "Cuatro personajes inquietos y deformes",

	"text_nans-nous":
		"Se estrenaron en 1890 para sustituir a los Nans Vells, que estaban en muy mal estado. Pero por suerte, estos últimos fueron restaurados y han seguido participando en La Patum hasta hoy.\n\nLos Nans Nous son dos parejas, una joven y la otra vieja, que danzan al son de los acordes de una melodía airosa y juguetona compuesta a finales del siglo XIX por Joaquim Serra i Farriols, Quimserra, que ha acabado siendo la música más popular y divulgada de La Patum. Figura en el repertorio de muchos grupos de danza tradicionales catalanes con el título de “La Patum de Berga”. Anteriormente se habían danzado varios ritmos de moda en esa época, como rigodones y habaneras.",

	titol_plens: "La apoteosis de la fiesta ",

	text_plens:
		"Son el mayor espectáculo de La Patum, su momento culminante, la apoteosis de la fiesta.\n\nEstán documentados desde 1621 y se cree que deben su nombre al hecho de que están llenos de fuego. Son, en suma, demonios llenos (plens) de fuego.\n\nHasta finales del siglo XIX, los Plens evolucionaban únicamente al son del Tabal, pero desde entonces saltan con la música genial y enardecedora que compuso Quimserra. Cuando se apagan las luces y empieza la música, la plaza se convierte en un infierno que se llena de fuego con mil fuets quemando a la vez. En total, saltan cien Plens, cada uno con nueve fuets. Cada Ple lleva un acompañante que lo guía a través de la plaza durante el salt.\n\nHay que decir que el número de Plens ha ido multiplicándose a lo largo de los siglos. En el siglo XVII se documentan dos diablos; antes de la Guerra Civil saltaban cuatro, ocho o, como mucho y de forma excepcional, doce. Cuando en 1916, con motivo de La Patum extraordinaria que se celebró en la Coronación Canónica de la Mare de Déu de Queralt, se hizo un salt de dieciséis Plens, la gente creyó que había demasiado fuego en la plaza y que suponía un peligro. Más adelante saltaron cuarenta, un número que ha ido creciendo hasta el centenar que saltan actualmente.",

	"El component fonamental": "Un componente fundamental",

	musica_1:
		"La música es un componente fundamental de la fiesta, sobre todo desde finales del siglo XIX, cuando las distintas melodías fueron incorporándose a La Patum. Hoy en día no se concibe La Patum sin las músicas interpretadas por la Cobla Pirineu, la Cobla Ciutat de Berga y la Banda de la Escuela Municipal de Música de Berga.\n\nLa mayor parte de la música de la fiesta es de carácter popular; se trata de una música sencilla y en algún aspecto única y genial. Entre los músicos de La Patum cabe destacar la figura de Joaquim Serra i Farriols, Quimserra (Berga, 1834-1906), autor de las músicas de los Turcs i Cavallets, los Plens y los Nans Nous. Fue él quien cambió radicalmente el panorama musical de La Patum, modernizándolo definitivamente.\n\nTambién es digno de mención Ricard Cuadra i Camprubí (Berga, 1951-1997), que organizó el Concierto de Música Olvidada de La Patum en 1993 para recuperar antiguas piezas que habían dejado de interpretarse. El éxito alcanzado, sin proponérselo, con la reintroducción de obras que llevaban décadas arrinconadas, hizo que este concierto tuviera continuidad en 1995 y 1997, con la recuperación de nuevas partituras. Su prematura muerte, en el mismo año 1997, llevó a instituir el Memorial Ricard Cuadra, que da continuidad a la labor que él dejo inacabada, con un concierto anual que se celebra el sábado de la Santísima Trinidad.",

	administradors_1: "Tradicionalmente, los encargados de recaudar dinero para pagar la fiesta",

	administradors_2:
		"Los Administradors y Administradores no son propiamente una comparsa de La Patum, pero sí tienen un papel importante.\n\nLa primera referencia documental de estas figuras data de 1619. Tradicionalmente, su tarea era recaudar fondos para sufragar los gastos de las fiestas de la Octava de Corpus.\n\nSe trata de cuatro parejas que representan los antiguos Quatre Barris en que se dividía la ciudad: Feixines o Capdamunt de la Vila (Fonolls), Sant Pere (Esclafidors Rebentats), Centre o calle Major (Esclafidors Embotits) y Raval (Ravenisses). Actualmente, tienen un papel protocolario y presiden las fiestas desde el balcón del Ayuntamiento.",

	patum_infantil_1:
		"La Patum Infantil se celebra el viernes de Corpus, aunque la actividad infantil de Berga empieza mucho antes. Los ensayos de La Patum Infantil duran dos semanas, unos días en los que los niños de la localidad aprenden los distintos bailes de la celebración. Por eso se considera una “escuela de patumaires”.\n\nEl viernes de Corpus, los niños y niñas de Berga representan su propia Patum en la plaza de Sant Pere. Esta Patum consta de los mismos elementos que la representada por los adultos, pero de unas dimensiones más reducidas.\n\nPor la mañana, La Patum Infantil empieza con un pasacalles por las calles de Berga, y al mediodía y por la tarde tienen lugar las representaciones de La Patum de Lluïment y La Patum completa con todas sus comparsas: Tabal, Turcs i Cavallets, Maces i Àngels, Guites, Àliga, Nans Vells, Gegants, Nans Nous, Plens y Tirabols.",

	"Per a més informació:": "Para más información:",

	patumaires_1:
		"Los artífices de La Patum son miles y miles de patumaires. Pero cada año, el Ayuntamiento de Berga distingue a una serie de personas que, por su implicación y colaboración en la fiesta, son merecedoras de un reconocimiento especial. Se les otorga el título de Patumaire o el de Patumaire d’Honor.",
	"Títol de Patumaire": "Título de Patumaire",
	"Patumaire d'honor": "Patumaire de honor",
	"Objecte i composició": "Objeto y Composición",
	text_objecte:
		"El Consejo Municipal de La Patum es un órgano de participación sectorial desde el 29 de marzo de 2017, que tiene como objetivo administrar, conservar y coordinar el desarrollo de la fiesta de La Patum, declarada Obra Maestra del Patrimonio Oral e Inmaterial de la Humanidad por la UNESCO, así como todas las actividades derivadas y relacionadas estrictamente con las comparsas de la fiesta, sus actuaciones y sus integrantes; velar por el buen uso del nombre y la imagen de la celebración, y de todo lo relacionado con su imagen corporativa, publicitaria y de difusión, además de todo lo que, de forma general o específica, incluya el nombre de Patum o se refiera a ella. Fue constituido el 24 de abril de 2001 como organismo autónomo municipal.\n\nEstá formado por representantes de los distintos grupos políticos municipales con representación en el consistorio, varios caps de colla, que acceden al cargo de manera rotativa, y representantes del colectivo patumaire.",
	Normativa: "Normativa",
	text_normativa:
		"El Consejo Municipal de La Patum, como órgano de participación sectorial, dispone de una normativa propia que regula su objetivo, composición y funcionamiento. ",
	"Acords de les sessions": "Acuerdos de las Sesiones",
	text_acords:
		"El Consejo Municipal de La Patum se reúne periódicamente durante todo el año en sesiones de carácter ordinario o extraordinario, en las que se tratan varios temas relacionados con la fiesta.",
	"Reglaments de règim intern de les comparses": "Reglamentos de régimen interno de las comparsas",
	text_reglaments:
		"Desde 2017, las comparsas de La Patum cuentan con su reglamento de régimen interno, en el que se detallan, entre otros aspectos, la composición, el funcionamiento y los mecanismos de los que dispone la ciudadanía para formar parte de ellas o participar en los ensayos o en los salts. Los reglamentos fueron ratificados por el Consejo Municipal de La Patum. ",
	Formularis: "Formularios",
	"Formularis de sol·licitud": "Formularios de solicitud",
	text_formularis:
		"El Ayuntamiento de Berga y el Consejo Municipal de La Patum promueven la participación de la ciudadanía y las entidades en distintas vertientes de La Patum: en el concurso de carteles de La Patum, en el proceso de selección de los Administradors y Administradores, en el sorteo de acceso al balcón durante las patums en la plaza o en el concurso de barras del concierto joven, entre otros. ",
	glossari_comparsa:
		"Cada uno de los grupos que se encargan de la actuación o el baile de las figuras de la fiesta: el Tabal, los Turcs i Cavallets, los Àngels, las Maces, la Guita Grossa, la Guita Xica, el Àliga, los Nans Vells, los Gegants, los Nans Nous y los Plens. ",
	glossari_passacarrers:
		"Recorridos por plazas y calles de Berga donde intervienen algunas comparsas de La Patum, con paradas fijas en las que efectúan los salts. Durante la semana de Corpus, hay tres pasacalles con recorridos distintos: miércoles al mediodía y al atardecer, y sábado al atardecer. ",
	glossari_salt:
		"Uno de sus significados es cada una de las actuaciones o cada uno de los bailes que realiza cada comparsa. También significa cada una de las rondas de actuaciones o bailes de las comparsas. En el pasacalles la ronda la forman las Maces, las Guites y los Gegants, mientras que en las patums en la plaza intervienen en la ronda todas las comparsas, empezando por los Turcs i Cavallets y finalizando con los Nans Nous. ",
	glossari_plens:
		"En un salt de Plens, la plaza de Sant Pere se llena con cien plens, sus acompañantes y las personas que quieren participar. Cuando se apagan las luces de la plaza y empieza la música, los acompañantes encienden con su bengala los nueve fuets que lleva cada ple. El baile se inicia girando dentro de la plaza en sentido contrario al de las agujas del reloj. Si se produce cualquier incidencia, se para la música y se encienden las luces. El baile finaliza en el momento en que han estallado todos los fuets. ",
	glossari_fuet:
		"Nombre con el que se conoce el material pirotécnico que utilizan las comparsas de fuego (Maces, Guites y Plens) durante su actuación. Se componen de una parte que se enciende y quema soltando chispas durante más de un minuto y de otra parte que acaba estallando al quemarse todo el fuet. ",
	glossari_tirabol:
		"Baile que pone punto final a los pasacalles y a las patums en la plaza, en el que los Gegants y las Guites bailan simultáneamente al son del Tabal y de los músicos mientras todo el mundo da vueltas en la plaza. ",
	glossari_barreja:
		"Bebidas alcohólicas ligadas históricamente a la fiesta de La Patum. La barreja lleva anís y moscatel, y el maumau se compone de vino tinto y gaseosa. Tradicionalmente se beben en bota y en porrón.",
	Càrrec: "Cargo",
	"Nom i cognoms del o la titular": "Nombre y apellidos del o de la titular",
	"Nom i cognoms del o la suplent": "Nombre y apellidos del o de la suplente",
	Glossari: "Glosario",
	Consells: "Consejos",
	"Vides de Patum": "Vidas de Patum",
	vides: "Para celebrar y recordar el reconocimiento de La Patum como Obra Maestra del Patrimonio Oral e Inmaterial de la Humanidad, en 2016 el Ayuntamiento de Berga inició la grabación de las entrevistas tituladas Vides de Patum, en las que varias personas que han participado activamente en la fiesta explican en primera persona sus vivencias y recuerdos.",
	"Dossiers de premsa": "Dossiers de prensa",
	Acreditacions: "Acreditaciones",
	Contacte: "Contacto",
	"Correu electrònic": "Correo electronico",
	"Correu electrònic premsa": "Correo electronico prensa",
	Telèfon: "Phone",
	"Disseny i programació": "Diseño y programación",
	patum_any: "Patum todo el año",
	patum_any_text:
		"La Patum todo el año tiene como objetivo potenciar y dar a conocer la fiesta durante todo el año, englobando las diferentes actividades programadas más allá de los actos principales de la semana de Corpus.",
	sorteig: "Sorteos",
};

export default TRANSLATIONS_ES;
