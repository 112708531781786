import React, { useEffect, useState } from "react";
import {
    Box,
    Container,
    Grid,
    makeStyles,
    Typography,
    useMediaQuery,
} from "@material-ui/core";
import ScrollAnimation from "react-animate-on-scroll";
import { useTranslation } from "react-i18next";
import i18n from "../../../lang";
import { getMiniEsdeveniments } from "../../../database/API";
import moment from "moment";
import MyButton from "../../../components/MyButton";
import { useNavigate } from "react-router";

const useStyles = makeStyles((theme) => ({
    root: {},
    main: {
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        backgroundColor: theme.palette.background.default,
        paddingBottom: 150,
    },
    imatge: {
        width: "100%",
        height: 150,
        backgroundPosition: "center",
        backgroundSize: "cover",
        borderRadius: 10,
        marginBottom: 20,
    },
    noticia: {
        flexDirection: "row",
        justifyContent: "flex-start",
        alignContent: "center",
        display: "flex",
        backgroundColor: "white",
        cursor: "pointer",
        padding: 15,
        borderRadius: 20,
        border: "2px solid white",
        transition: "0.3s",
        "&:hover": {
            backgroundColor: "transparent",
            "& .MuiTypography-body1": {
                color: "white",
            },
            "& .MuiTypography-h4": {
                color: "white",
            },
        },
    },
    data: {
        color: theme.palette.text.primary,
        fontSize: 12,
        lineHeight: "0.9",
        marginBottom: 5,
    },
    dataDia: {
        color: theme.palette.text.primary,
        fontSize: 40,
        lineHeight: "0.9",
    },
    titol: {
        marginBottom: 10,
        color: theme.palette.text.secondary,
    },
    dataWrap: {
        textAlign: "center",
        justifyContent: "center",
        flexDirection: "column",
        display: "flex",
    },
}));

const AgendaInici = ({ className, onMobileNavOpen, ...rest }) => {
    const classes = useStyles();
    const { t } = useTranslation();
    const [esdeveniments, setEsdeveniments] = useState();
    const matches = useMediaQuery("(min-width:960px)");
    const navigate = useNavigate();

    useEffect(() => {
        const get = async () => {
            const { data } = await getMiniEsdeveniments(i18n.language);
            setEsdeveniments(data);
        };
        get();
        i18n.moment.locale(i18n.language);
    }, [t]);

    return (
        <Box style={{ height: matches && "90vh" }} className={classes.main}>
            <ScrollAnimation animateIn="fadeIn" style={{ transition: "0.5s" }}>
                <Container className={classes.root}>
                    <Box my={8}>
                        <Typography variant="h1" style={{ color: "#fff" }}>
                            {t("Agenda")}
                        </Typography>
                    </Box>

                    <Grid>
                        {esdeveniments?.map((item) => {
                            return (
                                <Box
                                    key={item.titol}
                                    my={3}
                                    onClick={() =>
                                        navigate(
                                            "/actualitat/agenda/" + item.id,
                                            {
                                                state: item,
                                            }
                                        )
                                    }
                                >
                                    <Box pt={3} className={classes.noticia}>
                                        <Box
                                            mx={2}
                                            className={classes.dataWrap}
                                        >
                                            <Typography
                                                variant="body1"
                                                className={classes.data}
                                            >
                                                {i18n
                                                    .moment(item.data)
                                                    .format("dddd")}
                                            </Typography>
                                            <Typography
                                                variant="body1"
                                                className={classes.dataDia}
                                            >
                                                {i18n
                                                    .moment(item.data)
                                                    .format("DD")}
                                            </Typography>
                                            <Typography
                                                variant="body1"
                                                className={classes.data}
                                            >
                                                {i18n
                                                    .moment(item.data)
                                                    .format("MMMM")}
                                            </Typography>
                                        </Box>
                                        <Box mx={matches ? 4 : 2}>
                                            <Typography
                                                variant="h4"
                                                className={classes.titol}
                                            >
                                                {item.titol}
                                            </Typography>

                                            <Typography
                                                variant="body1"
                                                className={classes.data}
                                            >
                                                {t("A les")}{" "}
                                                {moment(
                                                    item.hora,
                                                    "HH:mm:ss"
                                                ).format("HH:mm")}
                                                {" h"}
                                            </Typography>

                                            <Typography
                                                variant="body1"
                                                className={classes.data}
                                            >
                                                {item.lloc}
                                            </Typography>
                                        </Box>
                                    </Box>
                                </Box>
                            );
                        })}
                        <Box pt={10} style={{ textAlign: "center" }}>
                            <MyButton
                                subtitle={t("Tots els esdeveniments")}
                                url="/actualitat/agenda"
                            />
                        </Box>
                    </Grid>
                </Container>
            </ScrollAnimation>
        </Box>
    );
};

export default AgendaInici;
