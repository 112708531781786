import React, { useEffect, useState } from 'react';
import {
    Box,
    Container,
    Grid,
    makeStyles,
    Typography,
    useMediaQuery,
} from '@material-ui/core';
import ScrollAnimation from 'react-animate-on-scroll';
import { useTranslation } from 'react-i18next';
import i18n from '../../../lang';
import { getMiniNoticies, publicUrl } from '../../../database/API';
import moment from 'moment';
import MyButton from '../../../components/MyButton';
import { useNavigate } from 'react-router';

const useStyles = makeStyles((theme) => ({
    root: {},
    main: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        backgroundColor: theme.palette.background.default,
        paddingBottom: 100,
    },
    imatge: {
        width: '100%',
        height: 150,
        backgroundPosition: 'center',
        backgroundSize: 'cover',
        borderRadius: 10,
        marginBottom: 20,
    },
    noticia: {
        backgroundColor: 'white',
        cursor: 'pointer',
        padding: 15,
        borderRadius: 20,
        border: '2px solid white',
        transition: '0.3s',
        '&:hover': {
            backgroundColor: 'transparent',
            '& .MuiTypography-body1': {
                color: 'white',
            },
        },
    },
    data: {
        color: theme.palette.text.primary,
        fontSize: 11,
        paddingBottom: 10,
    },
}));

const NoticiesInici = ({ className, onMobileNavOpen, ...rest }) => {
    const classes = useStyles();
    const { t } = useTranslation();
    const [noticies, setNoticies] = useState();
    const matches = useMediaQuery('(min-width:960px)');
    const navigate = useNavigate();

    useEffect(() => {
        const get = async () => {
            const { data } = await getMiniNoticies(i18n.language);
            setNoticies(data);
        };
        get();
    }, [t]);

    return (
        <Box style={{ height: matches && '90vh' }} className={classes.main}>
            <ScrollAnimation animateIn="fadeIn" style={{ transition: '0.5s' }}>
                <Container className={classes.root}>
                    <Box my={8}>
                        <Typography variant="h1" style={{ color: '#fff' }}>
                            {t('Notícies')}
                        </Typography>
                    </Box>

                    <Grid
                        container
                        spacing={4}
                        style={{ justifyContent: 'center' }}
                    >
                        {noticies?.map((item) => {
                            return (
                                <Grid
                                    key={item.titol}
                                    item
                                    md={3}
                                    sm={6}
                                    xs={12}
                                    style={{ display: 'flex' }}
                                    onClick={() =>
                                        navigate(
                                            '/actualitat/noticies/' + item.id,
                                            {
                                                state: item,
                                            }
                                        )
                                    }
                                >
                                    <Box pt={3} className={classes.noticia}>
                                        <Box
                                            className={classes.imatge}
                                            style={{
                                                backgroundImage: `url(${publicUrl}imatges/${item.foto}/noticies)`,
                                            }}
                                        />
                                        <Typography
                                            variant="body1"
                                            className={classes.data}
                                        >
                                            {moment(item.created_at).format(
                                                'DD/MM/YYYY'
                                            )}
                                        </Typography>
                                        <Typography variant="body1">
                                            {item.titol}
                                        </Typography>
                                    </Box>
                                </Grid>
                            );
                        })}
                        <Box pt={10}>
                            <MyButton
                                subtitle={t('Totes les notícies')}
                                url="/actualitat/noticies"
                            />
                        </Box>
                    </Grid>
                </Container>
            </ScrollAnimation>
        </Box>
    );
};

export default NoticiesInici;
