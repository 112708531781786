import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter } from "react-router-dom";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import * as serviceWorker from "./serviceWorker";
import Analytics from "analytics";
import googleAnalytics from "@analytics/google-analytics";
import { AnalyticsProvider } from "use-analytics";

// const analytics = Analytics({
//     app: 'la-patum',
//     page: ({ payload }) => {
//         console.log('Entra', payload);
//     },
//     track: ({ payload }) => {
//         console.log('Entra', payload);
//     },
//     plugins: [
//         googleAnalytics({
//             trackingId: 'G-W7HSSHJL4X',
//         }),
//     ],
// });

ReactDOM.render(
	<BrowserRouter>
		{/* <AnalyticsProvider instance={analytics}> */}
		<App />
		{/* </AnalyticsProvider> */}
	</BrowserRouter>,
	document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

serviceWorker.unregister();
