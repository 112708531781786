import React from 'react';
import { withStyles } from '@material-ui/styles';
import { Box, Button, makeStyles, Typography } from '@material-ui/core';
import { useNavigate } from 'react-router';

const BootstrapButton = withStyles(
    (theme) => ({
        root: {
            boxShadow: 'none',
            textTransform: 'none',
            borderRadius: 15,
            border: '2px solid ' + theme.palette.background.default,
            lineHeight: 1.5,
            backgroundColor: theme.palette.background.default,
            transition: '0.5s',
            '& .MuiTypography-h3': {
                color: 'white',
            },
            '& .MuiTypography-body1': {
                color: 'white',
                textTransform: 'uppercase',
            },
            padding: 5,
            '&:hover': {
                color: theme.palette.background.default,
                backgroundColor: 'transparent',
                boxShadow: 'none',
                '& .MuiTypography-h3': {
                    color: theme.palette.background.default,
                },
                '& .MuiTypography-body1': {
                    color: theme.palette.background.default,
                },
            },
            '&:active': {
                boxShadow: 'none',
                backgroundColor: theme.palette.background.default,
                borderColor: theme.palette.background.default,
            },
            '&:focus': {
                boxShadow: '0 0 0 0.1rem ',
            },
        },
        label: {
            color: 'white',
        },
    }),
    { withTheme: true }
)(Button);

const useStyles = makeStyles((theme) => ({
    margin: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1),
        paddingLeft: 20,
        paddingRight: 20,
    },
    button: {
        flexDirection: 'columns',
    },
}));

export default function MyButtonMiniInv(props) {
    const { title, subtitle, fullWidth, url, ...rest } = props;
    const classes = useStyles();
    const navigate = useNavigate();

    if (url) {
        return (
            <BootstrapButton
                variant="contained"
                color="primary"
                disableRipple
                className={classes.margin}
                fullWidth={fullWidth}
                onClick={() => navigate(url)}
                {...rest}
            >
                <Box className={classes.button}>
                    <Typography variant="body1">{title}</Typography>
                    <Typography variant="body1">{subtitle}</Typography>
                </Box>
            </BootstrapButton>
        );
    } else {
        return (
            <BootstrapButton
                variant="contained"
                color="primary"
                disableRipple
                className={classes.margin}
                fullWidth={fullWidth}
                {...rest}
            >
                <Box className={classes.button}>
                    <Typography variant="body1">{title}</Typography>
                    <Typography variant="body1">{subtitle}</Typography>
                </Box>
            </BootstrapButton>
        );
    }
}
