import React, { useState } from "react";
import { NavLink as RouterLink } from "react-router-dom";
import PropTypes from "prop-types";
import { Typography, Link as LinkTo, Box, Menu } from "@material-ui/core";
import navBarStyle from "./NavBarStyle";
import { withStyles } from "@material-ui/styles";

const StyledMenu = withStyles({
    paper: {
        marginTop: 10,
        paddingLeft: 10,
        paddingRight: 10,
        backgroundColor: "#d9594e",
        borderRadius: 10,
    },
})((props) => (
    <Menu
        elevation={0}
        getContentAnchorEl={null}
        disableScrollLock={true}
        anchorOrigin={{
            vertical: "bottom",
        }}
        {...props}
    />
));

const NavItem = ({ className, item, mobile, closeMenu, ...rest }) => {
    const classes = navBarStyle();
    const [open, setOpen] = useState(false);

    const handleClick = (event) => {
        setOpen(event.currentTarget);
    };

    const handleClose = () => {
        setOpen(null);
        closeMenu();
    };

    return (
        <Box className={classes.item} {...rest}>
            {item.to !== "" ? (
                <LinkTo
                    className={classes.button}
                    component={RouterLink}
                    to={item.to}
                    end
                    underline="none"
                    onClick={() => closeMenu()}
                >
                    <Typography
                        className={mobile ? classes.titleMobile : classes.title}
                    >
                        {item.title}
                    </Typography>
                </LinkTo>
            ) : (
                <div>
                    <div className={classes.button} onClick={handleClick}>
                        <Typography
                            className={
                                mobile ? classes.titleMobile : classes.title
                            }
                        >
                            {item.title}
                        </Typography>
                    </div>
                    <StyledMenu
                        id="customized-menu"
                        anchorEl={open}
                        keepMounted
                        open={Boolean(open)}
                        onClose={handleClose}
                    >
                        {item.subMenu.map((item) => {
                            return (
                                <LinkTo
                                    component={RouterLink}
                                    key={item.title}
                                    to={item.to}
                                    end
                                    onClick={handleClose}
                                >
                                    <Typography className={classes.titleSub}>
                                        {item.title}
                                    </Typography>
                                </LinkTo>
                            );
                        })}
                    </StyledMenu>
                </div>
            )}
        </Box>
    );
};

NavItem.propTypes = {
    className: PropTypes.string,
    href: PropTypes.string,
    icon: PropTypes.elementType,
    title: PropTypes.string,
};

export default NavItem;
