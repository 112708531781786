import { Box, makeStyles, Typography, useMediaQuery } from "@material-ui/core";
import React from "react";

const useStyles = makeStyles((theme) => ({
    main: {
        flex: 1,
        backgroundColor: "white",
        position: "relative",
    },
    background: {
        textAlign: "center",
    },
    noBackground: {
        color: "white",
    },
}));

const Header = (props) => {
    const { title, background, subtitle } = props;
    const classes = useStyles();
    const matches = useMediaQuery("(min-width:960px)");
    return (
        <Box
            my={matches ? 8 : 5}
            py={3}
            className={background && classes.main}
            style={{ zIndex: 10 }}
        >
            <Typography
                variant="h1"
                className={
                    background ? classes.background : classes.noBackground
                }
            >
                {title}
            </Typography>
            {subtitle && (
                <Typography
                    variant="h2"
                    className={
                        background ? classes.background : classes.noBackground
                    }
                >
                    {subtitle}
                </Typography>
            )}
        </Box>
    );
};

export default Header;
