import {
    Box,
    Container,
    Divider,
    Grid,
    List,
    ListItem,
    ListItemText,
    makeStyles,
    Typography,
    useMediaQuery,
} from "@material-ui/core";
import React, { useEffect, useState } from "react";
import ScrollAnimation from "react-animate-on-scroll";
import { useTranslation } from "react-i18next";
import Header from "../../components/Header";
import Page from "../../components/Page";
import Footer from "../../components/Footer";
import Logo from "../../components/Logo";
import { ReactComponent as Administradors } from "../../theme/icons/administradors-sol.svg";
import theme from "../../theme";
import Divide from "../../components/Divide";
import { Element } from "react-scroll";
import { getAdministradors } from "../../database/API";

const useStyles = makeStyles((theme) => ({
    root: {
        marginTop: 100,
    },
    main: {
        paddingTop: 100,
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        backgroundColor: theme.palette.background.default,
        alignContent: "center",
    },
    main2: {
        paddingBottom: 200,
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        backgroundColor: "white",
        alignContent: "center",
    },
    mainM: {
        paddingTop: 50,
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        backgroundColor: theme.palette.background.default,
        alignContent: "center",
    },
    image: {
        width: "100%",
    },
    list: {
        backgroundColor: theme.palette.background.default,
        borderRadius: 20,
        padding: 20,
    },
}));

const MusicaScreen = () => {
    const classes = useStyles();
    const { t } = useTranslation();
    const [admin, setAdmin] = useState();
    const [any, setAny] = useState("2021");
    const matches = useMediaQuery("(min-width:960px)");

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    useEffect(() => {
        const get = async () => {
            const { data } = await getAdministradors();
            setAdmin(data);
            setAny(data[0].any);
        };
        get();
    }, []);

    return (
        <Page title={t("Administradors/es")}>
            <Box className={matches ? classes.main : classes.mainM}>
                <ScrollAnimation
                    animateIn="fadeIn"
                    style={{ transition: "0.5s" }}
                >
                    <Container className={classes.root}>
                        <Box>
                            <Header title={t("Administradors/es")} />
                        </Box>
                    </Container>
                </ScrollAnimation>
            </Box>
            <Element name="content" className={classes.main2}>
                <ScrollAnimation
                    animateIn="fadeIn"
                    style={{ transition: "0.5s" }}
                >
                    <Container className={classes.root}>
                        <Grid container spacing={10}>
                            <Grid item md={6}>
                                <Logo
                                    icon={Administradors}
                                    color={theme.palette.text.primary}
                                    height={200}
                                    width={200}
                                />
                                <Box py={3} />
                                <Typography variant="h2">
                                    {t("administradors_1")}
                                </Typography>
                            </Grid>
                        </Grid>
                        <Divide color />
                        <Box py={3} />

                        <Grid container spacing={10}>
                            <Grid item md={6}>
                                <Typography
                                    variant="body1"
                                    style={{ whiteSpace: "pre-line" }}
                                >
                                    {t("administradors_2")}
                                </Typography>

                                <Box py={5}>
                                    <img
                                        alt={t("Administradors/es")}
                                        src={`${process.env.PUBLIC_URL}/images/administradors.jpg`}
                                        className={classes.image}
                                    />
                                </Box>
                            </Grid>
                            <Grid item md={6} style={{ width: "100%" }}>
                                <List component="nav" className={classes.list}>
                                    <ListItem>
                                        <ListItemText
                                            classes={classes.listItem}
                                            color="white"
                                            primary={
                                                <Typography
                                                    variant="h3"
                                                    style={{
                                                        color: "#FFFFFF",
                                                    }}
                                                >
                                                    {t("Administradors/es") +
                                                        " " +
                                                        any}
                                                </Typography>
                                            }
                                        />
                                    </ListItem>
                                    <Divider
                                        style={{
                                            width: "90%",
                                            backgroundColor: "white",
                                            marginLeft: 18,
                                        }}
                                    />
                                    {admin?.map((item) => {
                                        return (
                                            <ListItem>
                                                <ListItemText
                                                    classes={classes.listItem}
                                                    color="white"
                                                    primary={
                                                        <Typography
                                                            type="body1"
                                                            style={{
                                                                color: "#383838",
                                                            }}
                                                        >
                                                            {item.titol}
                                                        </Typography>
                                                    }
                                                    secondary={
                                                        <>
                                                            <Typography
                                                                type="body1"
                                                                style={{
                                                                    color: "#FFFFFF",
                                                                    whiteSpace:
                                                                        "pre-line",
                                                                }}
                                                            >
                                                                {item.nom1}
                                                            </Typography>
                                                            <Typography
                                                                type="body1"
                                                                style={{
                                                                    color: "#FFFFFF",
                                                                    whiteSpace:
                                                                        "pre-line",
                                                                }}
                                                            >
                                                                {item.nom2}
                                                            </Typography>
                                                        </>
                                                    }
                                                />
                                            </ListItem>
                                        );
                                    })}
                                </List>
                            </Grid>
                        </Grid>
                    </Container>
                </ScrollAnimation>
            </Element>
            <Footer />
        </Page>
    );
};

export default MusicaScreen;
