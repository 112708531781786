import { Box, Container, Grid, makeStyles, Typography, useMediaQuery } from "@material-ui/core";
import React, { useEffect } from "react";
import ScrollAnimation from "react-animate-on-scroll";
import { useTranslation } from "react-i18next";
import Header from "../../components/Header";
import Page from "../../components/Page";
import Footer from "../../components/Footer";
import Logo from "../../components/Logo";
import { ReactComponent as Infantil } from "../../theme/icons/patum-infantil-sol.svg";
import theme from "../../theme";
import Divide from "../../components/Divide";
import { Element } from "react-scroll";
import MyButtonInv from "../../components/MyButtonInv";

const useStyles = makeStyles((theme) => ({
	root: {
		marginTop: 100,
	},
	main: {
		paddingTop: 100,
		display: "flex",
		flexDirection: "column",
		justifyContent: "center",
		backgroundColor: theme.palette.background.default,
		alignContent: "center",
	},
	main2: {
		paddingBottom: 200,
		display: "flex",
		flexDirection: "column",
		justifyContent: "center",
		backgroundColor: "white",
		alignContent: "center",
	},
	mainM: {
		paddingTop: 50,
		display: "flex",
		flexDirection: "column",
		justifyContent: "center",
		backgroundColor: theme.palette.background.default,
		alignContent: "center",
	},
	image: {
		width: "100%",
	},
}));

const PatumInfantil = () => {
	const classes = useStyles();
	const { t } = useTranslation();
	const matches = useMediaQuery("(min-width:960px)");

	useEffect(() => {
		window.scrollTo(0, 0);
	}, []);

	return (
		<Page title={t("patum_infantil")}>
			<Box className={matches ? classes.main : classes.mainM}>
				<ScrollAnimation animateIn="fadeIn" style={{ transition: "0.5s" }}>
					<Container className={classes.root}>
						<Box>
							<Header title={t("patum_infantil")} />
						</Box>
					</Container>
				</ScrollAnimation>
			</Box>
			<Element name="content" className={classes.main2}>
				<ScrollAnimation animateIn="fadeIn" style={{ transition: "0.5s" }}>
					<Container className={classes.root}>
						<Grid container spacing={10}>
							<Grid item xs={6}>
								<Logo icon={Infantil} color={theme.palette.text.primary} height={200} width={200} />
								<Box py={3} />
								<Typography variant="h2">{t("Escola de Patumaires")}</Typography>
							</Grid>
						</Grid>
						<Divide color />
						<Box py={3} />

						<Grid container spacing={10}>
							<Grid item md={6}>
								<Typography variant="body1" style={{ whiteSpace: "pre-line" }}>
									{t("patum_infantil_1")}
								</Typography>
								<Box py={3} />

								<MyButtonInv url="http://www.patuminfantil.cat/" target="_blank" subtitle={t("Visita el Web")} fullWidth external />
								<Box py={5}>
									<img alt="Dissabte 1" src={`${process.env.PUBLIC_URL}/images/patum-infantil.jpg`} className={classes.image} />
									<Typography>Foto: La Fotogràfica</Typography>
								</Box>
							</Grid>
							<Grid item md={6} style={{ width: "100%" }}>
								<Box textAlign="right">
									<img
										alt="Cartell Patum Infantil"
										src={`${process.env.PUBLIC_URL}/images/CartellPatumInfantil2024.jpeg`}
										className={classes.image}
									/>
								</Box>
							</Grid>
						</Grid>
					</Container>
				</ScrollAnimation>
			</Element>
			<Footer />
		</Page>
	);
};

export default PatumInfantil;
